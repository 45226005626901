import { withClassNames } from '~/styles'
import React from 'react'
import { Series } from '~/interfaces'
import s from './tangibles.module.scss'
const cx = withClassNames(s)

export const Tangibles = ({ series, children }: any) => {
  return <div className={cx('collectibles-container', series)}>{children}</div>
}

export interface ICollectibles {
  series?: Series
  children: React.ReactNode
}
