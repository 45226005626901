import { Interpolation, Theme } from '@emotion/react'
import { useRouter } from 'next/router'
import { ButtonHTMLAttributes, ClassAttributes, useState } from 'react'
import { InferQueryOutput, trpc } from '~/api/client'
import { useAuth } from '~/auth/client'
import { addEmptyCollectionIfNotExists } from '~/flow/cadence/query'
import { useUser } from '~/flow/useCurrentUser.hook'
import { FCC, Series } from '~/interfaces'
import {
  useSelectedPackTypeCtxLowerCase,
  useSelectedPackTypeDetails,
} from '../contexts/SelectedPackTypeContext'
import { Link, LinkProps } from './Link'
import * as fcl from '@onflow/fcl'
import { useRouteChangeLoading } from '../utils/utils'
import { useSaleEnded } from '~/analytics/growthbook'

export const ButtonLink: FCC<
  LinkProps & {
    buttonProps?: ClassAttributes<HTMLButtonElement> &
      ButtonHTMLAttributes<HTMLButtonElement> & {
        css?: Interpolation<Theme>
      }
  }
> = ({ children, buttonProps, ...props }) => {
  return (
    <Link noAnchor={true} {...props}>
      <button className={props.className} {...buttonProps}>
        {children}
      </button>
    </Link>
  )
}

export const AuthButton = () => {
  const { currentUser } = useUser()
  if (currentUser?.loggedIn) return <SignOutButton />

  return <SignInButton />
}

export const AuthButtonAlternate = () => {
  const { currentUser } = useUser()
  if (currentUser?.loggedIn) return <SignOutButton />

  return <SignInButtonAlternate />
}

export const AuthButtonDisabled = () => {
  return (
    <button className='is-small hidden'>
      SIGN IN / SIGN UP
    </button>
  )
}

const SignInButton = () => {
  const { signIn } = useAuth()
  return (
    <button className='is-small' onClick={signIn}>
      SIGN IN / SIGN UP
    </button>
  )
}

const SignInButtonAlternate = () => {
  const { signIn } = useAuth()
  return (
    <button className='is-small' onClick={signIn}>
      SIGN IN
    </button>
  )
}

const SignOutButton = () => {
  const { signOut } = useAuth()
  return (
    <button className='is-small' onClick={signOut}>
      Sign Out
    </button>
  )
}

// TODO: Please note these button components below need some though put into their names
export const BuyButton = ({ unavailable = false }) => {
  const { currentUser, session, signIn } = useAuth()
  const router = useRouter()
  const packType = useSelectedPackTypeCtxLowerCase()
  const boxType = router.query.series?.includes('box') ? '-box' : ''

  const [loading, setLoading] = useState(false)
  const isProdSaleEnded = useSaleEnded()

  const canPurchaseMetric = useSeries3PurchaseMetrics()
  const routeChangeLoading = useRouteChangeLoading()

  if (unavailable || isProdSaleEnded.on) {
    return <button disabled>Sale Ended</button>
  }

  if (routeChangeLoading || loading)
    return (
      <button disabled>
        Processing<span className={'loading-dots'}></span>
      </button>
    )
  if (currentUser.loggedIn) {
    return (
      <CanPurchaseDebugger
        name={!boxType ? 'packs' : 'boxes'}
        isLoggedIn={currentUser.loggedIn}
      >
        <InitiatePurchaseButton canBuyMore={canPurchaseMetric.canBuyMore} />
      </CanPurchaseDebugger>
    )
  }

  const enforceSignInBeforeRedirect = async () => {
    setLoading(true)
    try {
      let addr = currentUser.addr!
      if (!currentUser.loggedIn) {
        const authed = await signIn()
        addr = authed.addr!
      }
      await addEmptyCollectionIfNotExists(addr)
      setLoading(false)
      router.push(`/payment/${packType}${boxType}/${router.query.series}`)
    } catch (err) {
      setLoading(false)
    }
  }

  return (
    <CanPurchaseDebugger
      name={!boxType ? 'packs' : 'boxes'}
      isLoggedIn={currentUser.loggedIn}
    >
      <button className='pulsingButton' onClick={enforceSignInBeforeRedirect}>
        Buy now
      </button>
    </CanPurchaseDebugger>
  )
}

// TODO: make a pack sold out useHook
const InitiatePurchaseButton = ({ canBuyMore = true }) => {
  const packType = useSelectedPackTypeCtxLowerCase()
  const packDetails = useSelectedPackTypeDetails()
  const router = useRouter()
  const boxType = router.query.series?.includes('box') ? '-box' : ''
  const { signIn, currentUser, session } = useAuth()
  const [loading, setLoading] = useState(false)

  if (loading)
    return (
      <button disabled>
        <span>
          Processing<span className={'loading-dots'}></span>
        </span>
      </button>
    )
  if (packDetails.available <= 0) return <SoldOutButton />

  if (!canBuyMore) {
    return (
      <ButtonLink href={'#'} buttonProps={{ disabled: true }}>
        Limit Reached
      </ButtonLink>
    )
  }

  // TODO: refactor this to tie it in with the enforceSignInBeforeRedirect in the BuyButton component above
  const verifyHasEmptyCollectionBeforePaymentRedirect = async (e) => {
    setLoading(true)
    try {
      let addr = currentUser.addr!
      if (!currentUser.loggedIn) {
        const authed = await signIn()
        addr = authed.addr!
      }
      await addEmptyCollectionIfNotExists(addr)
      setLoading(false)
      router.push(`/payment/${packType}${boxType}/${router.query.series}`)
    } catch (err) {
      setLoading(false)
    }
  }

  return (
    <button
      className='pulsingButton'
      onClick={verifyHasEmptyCollectionBeforePaymentRedirect}
    >
      Buy now
    </button>
  )
}

const SoldOutButton = () => {
  return <button disabled>Sold Out</button>
}

export function useSeries3PurchaseMetrics(): NonNullable<
  InferQueryOutput<'getPackLimit'>
>['series3']['boxes']['premium'] {
  const { currentUser } = useAuth()
  const purchaseLimits = trpc.useQuery([
    'getPackLimit',
    {
      address: currentUser.addr,
    },
  ])
  const router = useRouter()
  const boxType = router.query.series?.includes('box') ? '-box' : ''
  const packType = useSelectedPackTypeCtxLowerCase() + boxType

  if (!purchaseLimits.data) {
    return {
      canBuyMore: true,
      canBuy: 0,
      purchased: 0,
      totalCanBuy: 0,
    }
  }

  const item = packType.toLowerCase().includes('box') ? 'boxes' : 'packs'
  const type = packType.toLowerCase().includes('premium')
    ? 'premium'
    : 'standard'

  const canPurchaseData = purchaseLimits.data.series3
  if (false && process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
    console.log('getCanPurchaseMetric', {
      packType,
      canPurchaseData,
      item,
      type,
      data: canPurchaseData[item][type],
    })
  }
  return canPurchaseData[item][type]
}

export function CanPurchaseDebugger({ children, name, isLoggedIn }: any) {
  const canPurchaseData = useSeries3PurchaseMetrics()

  return (
    <>
      {children}
      {isLoggedIn && (
        <p>
          You can purchase {canPurchaseData.canBuy} more {name}.
        </p>
      )}
      {process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
        ? null
        : JSON.stringify(canPurchaseData, null, 2)}
    </>
  )
}
