import { createContext, useContext } from 'react'
import { FCC, IPack, Series, ROUTE } from '~/interfaces'
import { ButtonLink, If } from 'components'
import { useRouter } from 'next/router'

import { withClassNames } from 'styles'
import s from './packs-list.module.scss'
export const cx = withClassNames(s)

import { motion } from 'framer-motion'
import { Route } from '~/client/utils/enum'

export const PacksDisplayList = ({ children }: any) => {
  const { pathname } = useRouter()

  const PackListAnimation = {
    enter: {
      transition: {
        delayChildren: pathname === Route.Packs ? 0 : 0.75,
        staggerChildren: pathname === Route.Packs ? 0.3 : 0,
      },
    },
  }

  return (
    <motion.div
      variants={PackListAnimation}
      className={cx('packs-list-container')}
    >
      {children}
    </motion.div>
  )
}

export interface PacksDisplayListProps {
  packs: IPack[]
}

export const defaultValue = {
  packs: [],
}
export const PacksDisplayListContext =
  createContext<PacksDisplayListProps>(defaultValue)

export const PacksDisplayListProvider: FCC<{
  value?: PacksDisplayListProps
}> = ({ children, value = defaultValue }) => {
  return (
    <PacksDisplayListContext.Provider value={value}>
      {children}
    </PacksDisplayListContext.Provider>
  )
}

export const usePacksDisplayListCtx = () => useContext(PacksDisplayListContext)

export const PackActionButton = (props: IPack & { series: Series }) => {
  if (
    (props.series === Series.three || props.series === Series.threeBox) &&
    props.available > 0
  ) {
    return (
      <ButtonLink
        href={`${ROUTE.packs}/${props.series}/${props.type.toLowerCase()}`}
      >
        View {props.series.includes('box') ? 'Box' : 'Packs'}
      </ButtonLink>
    )
  }

  return (
    <ButtonLink
      href={`${ROUTE.packs}/${props.series}/${props.type.toLowerCase()}`}
    >
      <If cond={props.series == Series.one}>Sold Out</If>
      <If cond={props.series === Series.two}>View Pack</If>
      <If cond={props.series === Series.threeBox}>Sold Out</If>
      <If cond={props.series === Series.three}>Sold Out waaaaaa</If>
    </ButtonLink>
  )
}
