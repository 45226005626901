import { useEffect } from 'react'
import { FCC } from '~/interfaces'
import { withClassNames } from '~/styles'
import { If } from '../If'
import s from './modal.module.scss'
const cx = withClassNames(s)
import { AnimatePresence, motion, Variants } from 'framer-motion'
import { ModalAnimation } from '../Animations'
import { Canvas } from '../Canvas/Canvas'

interface IAnimation {
  open: {}
  closed: {}
}

interface ModalProps {
  roundedCorners?: boolean
  showBackground?: boolean
  backgroundClickable?: boolean
  showClose?: boolean
  modalOpen: boolean
  modalAnimation?: Variants
  backgroundAnimation?: Variants
  legendary?: any
  showBorder?: boolean
  glow?: string
  onClose: (value: boolean) => void
}

export const Modal: FCC<ModalProps> = ({
  children,
  roundedCorners = true,
  showBackground = true,
  backgroundClickable = true,
  showClose = true,
  modalOpen,
  modalAnimation = ModalAnimation,
  backgroundAnimation,
  legendary,
  showBorder = false,
  glow = '',
  onClose,
}) => {
  useEffect(() => {
    if (modalOpen)
      return document.querySelector('body')?.classList.add('lock-scroll')
    document.querySelector('body')?.classList.remove('lock-scroll')
  }, [modalOpen])

  const toggleModal = () => onClose(!modalOpen)

  const onBackgroundClick = () => {
    if (backgroundClickable) {
      toggleModal()
    }
  }

  return (
    <AnimatePresence exitBeforeEnter>
      {modalOpen && (
        <div className={cx('main-container')}>
          {showBackground && (
            <motion.div
              variants={backgroundAnimation}
              initial='closed'
              animate='open'
              exit='closed'
              title={backgroundClickable ? 'Close' : ''}
              onClick={onBackgroundClick}
              className={cx('background', {
                [cx('is-clickable')]: backgroundClickable,
              })}
            >
              <If
                cond={['legendary', 'legendary refractor'].includes(
                  legendary?.plays.rarities.name.toLowerCase(),
                )}
              >
                <Canvas
                  startAnimation={['legendary', 'legendary refractor'].includes(
                    legendary?.plays.rarities.name.toLowerCase(),
                  )}
                />
              </If>
            </motion.div>
          )}
          <motion.div
            variants={modalAnimation}
            initial='closed'
            animate='open'
            exit='closed'
            className={cx('modal-container')}
          >
            <motion.div
              className={cx('modal', glow, {
                [cx('has-rounded-corners')]: roundedCorners,
                [cx('has-border-shadow')]: showBorder,
              })}
            >
              <If cond={showClose}>
                <div className={cx('header')}>
                  <div onClick={toggleModal} className={cx('close-icon')}></div>
                </div>
              </If>
              <div className={cx('content')}>{children}</div>
              <div className={cx('footer')}></div>
            </motion.div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}
