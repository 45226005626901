import React, { useEffect, useRef } from 'react'

let fireworkArray: Firework[] = []
let particleArray: Particle[] = []

export const Canvas = ({ startAnimation = false }) => {
  const size: CanvasSize = {
    width: window.innerWidth,
    height: window.innerHeight,
  }
  const canvasRef: any = useRef(null)
  const requestIdRef: any = useRef(null)
  let ctx: CanvasRenderingContext2D

  useEffect(() => {
    ctx = canvasRef.current.getContext('2d')
  })

  useEffect(() => {
    if (!startAnimation) return
    requestIdRef.current = requestAnimationFrame(tick)
    return () => cancelAnimationFrame(requestIdRef.current)
  })

  const renderFrame = () => {
    ctx.fillStyle = 'rgba(0,0,0,0.1)'
    ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height)

    if (fireworkArray.length < 4) {
      const x = Math.random() * canvasRef.current.width
      const y = canvasRef.current.height
      const height = Math.floor(Math.random() * 20)
      const yVel = 7
      fireworkArray.push(new Firework(x, y, height, yVel, ctx))
    }

    for (let i = 0; i < fireworkArray.length; i++) {
      fireworkArray[i].update()
    }

    for (let i = 0; i < particleArray.length; i++) {
      particleArray[i].update()
    }

    fireworkArray = fireworkArray.filter((obj) => !obj.boom)
    particleArray = particleArray.filter((obj) => !obj.fade)
  }

  const tick = () => {
    if (!canvasRef.current) return
    renderFrame()
    requestIdRef.current = requestAnimationFrame(tick)
  }

  return <canvas {...size} ref={canvasRef}></canvas>
}

class Firework {
  x: number
  y: number
  height: number
  yVel: number
  ctx: CanvasRenderingContext2D
  radius = 5
  boom = false
  boomHeight = Math.floor(Math.random() * 200) + 50

  constructor(x: number, y: number, height: number, yVel: number, ctx: any) {
    this.x = x
    this.y = y
    this.yVel = yVel
    this.height = height
    this.ctx = ctx
  }

  draw() {
    this.ctx.fillStyle = '#ffcf50'
    this.ctx.strokeStyle = '#ffcf50'
    this.ctx.beginPath()
    this.ctx.stroke()
    this.ctx.beginPath()
    this.ctx.arc(this.x, this.y, 3, Math.PI * 2, 0, false)
    this.ctx.fill()
  }

  update() {
    this.y -= this.yVel
    if (this.radius < 20) {
      this.radius += 0.35
    }

    if (this.y < this.boomHeight) {
      this.boom = true

      for (let i = 0; i < 120; i++) {
        particleArray.push(
          new Particle(this.x, this.y, Math.random() * 2 + 0.1, 1, this.ctx),
        )
      }
    }
    this.draw()
  }
}

class Particle {
  x: number
  y: number
  radius: number
  A: number
  timer = 0
  fade = false
  xVel = Math.random() * 10 - 4
  yVel = Math.random() * 10 - 4
  ctx: CanvasRenderingContext2D

  constructor(x: number, y: number, radius: number, A: number, ctx: any) {
    this.x = x
    this.y = y
    this.radius = radius
    this.A = A
    this.ctx = ctx
  }

  draw() {
    this.ctx.save()
    this.ctx.beginPath()
    this.ctx.fillStyle = '#fffb89'
    this.ctx.globalCompositeOperation = 'screen'
    this.ctx.arc(this.x, this.y, this.radius, Math.PI * 2, 0, false)
    this.ctx.fill()
    this.ctx.restore()
  }

  update() {
    this.x += this.xVel
    this.y += this.yVel

    this.A -= 0.02
    if (this.A < 0) {
      this.fade = true
    }
    this.draw()
  }
}

interface CanvasSize {
  width: number
  height: number
}

interface Firework {
  boom: boolean
  boomHeight: number
  ctx: CanvasRenderingContext2D
  height: number
  radius: number
  x: number
  y: number
  yVel: number
}

interface Particle {
  A: number
  ctx: CanvasRenderingContext2D
  fade: boolean
  radius: number
  timer: number
  x: number
  y: number
  xVel: number
  yVel: number
}
