import { Link } from '../Link'
import s from './footer.module.scss'
import {
  DiscordIcon,
  InstagramIcon,
  TwitchIcon,
  TwitterIcon,
  YoutubeIcon,
} from '../SvgIcons'

export const Footer = () => {
  return (
    <div className={s['footer-container']}>
      <div className={s['links-container']}>
        <Link href='mailto:Info@abdcollectibles.com'>Contact Us</Link>
        <Link href='/help'>Help</Link>
      </div>

      <div className={s['links-container']}>
        <Link
          target='_blank'
          href='https://www.instagram.com/abdcollectibles/?hl=en'
        >
          <InstagramIcon />
        </Link>
        <Link target='_blank' href='https://twitter.com/abdcollectibles'>
          <TwitterIcon />
        </Link>
        <Link target='_blank' href='https://discord.gg/zn93VkRpwA'>
          <DiscordIcon />
        </Link>
        <Link target='_blank' href='https://twitch.tv/abdcollectibles/'>
          <TwitchIcon />
        </Link>
        <Link target='_blank' href='https://www.youtube.com/channel/UCJNlu09qOr7bGnwl4JWAx8g'>
          <YoutubeIcon />
        </Link>
      </div>

      <div className={s['links-container']}>
        <Link href='/privacy'>Privacy Policy</Link>
        <Link href='/terms-conditions'>Terms and Conditions</Link>
        <span>ABD Collectibles LLC, 2022</span>
      </div>
    </div>
  )
}
