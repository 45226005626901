import '../config'
import * as fcl from '@onflow/fcl'
import * as fclTypes from '@onflow/types'
import GetSetNameCDC from './queries/get_set_name.cdc'
import GetSetSeriesCDC from './queries/get_set_series.cdc'
import GetNextPlayIDCDC from './queries/get_next_play_id.cdc'
import GetNextSetIDCDC from './queries/get_next_set_id.cdc'
import GetNextSetNameCDC from './queries/get_set_name.cdc'
import GetNextSeriesIDCDC from './queries/get_next_series_id.cdc'
import BorrowMomentCDC from './queries/borrow_moment.cdc'

export const flowQuery = async <Result = any>(
  transactionScript: string,
  args: fcl.ArgumentFunction = () => [],
  limit: number = 999,
) => {
  const result = await fcl.query({
    cadence: transactionScript,
    limit: fcl.limit(limit),
    args,
  })

  return result as Result
}
export const checkEmptyCollection = async (userAddress: string) => {
  try {
    const response = await flowQuery(checkEmptyCollectionScript, (arg, t) => [
      arg(userAddress, fclTypes.Address),
    ])
    return true
  } catch (err) {
    return false
  }
}

export const addEmptyCollectionIfNotExists = async (address: string) => {
  const hasEmptyCollection = await checkEmptyCollection(address)
  if (!hasEmptyCollection) {
    await addEmptyCollection(address)
    const success = await waitForEmptyCollection(address)
  }
  return true
}

export const addEmptyCollection = async (userAddress: string) => {
  try {
    const tx = await fcl.send([
      fcl.proposer(fcl.authz),
      fcl.payer(fcl.authz),
      fcl.authorizations([fcl.authz]),
      fcl.limit(9999),
      fcl.transaction(addEmptyCollectionScript),
    ])

    // TODO: use transaction state tracking to allow for callees to know about transaction state
    const status = await fcl.tx(tx).onceSealed()
    return 'Confirmed'
  } catch (err) {
    throw err
  }
}

export const waitForEmptyCollection = async (userAddress: string) => {
  const hasEmptyCollection = await checkEmptyCollection(userAddress)
  if (!hasEmptyCollection) {
    await wait(500)
    await waitForEmptyCollection(userAddress)
  }
  return hasEmptyCollection
}

if (typeof window !== 'undefined') {
  // @ts-ignore
  window.__debug = {
    fcl: fcl,
    addEmptyCollection,
    checkEmptyCollection,
  }
}

// TODO: find a way to make this readable from a cadence file, like an api call or something
const checkEmptyCollectionScript = `import ABD from 0xABDProfile

// list of all moments' ids an account owns
pub fun main(account: Address): [UInt64] {
  let acct = getAccount(account)
  let capability = acct.getCapability<&{ABD.MomentCollectionPublic}>(/public/ABDMomentCollection)
  let collectionRef = capability.borrow()
          ?? panic("Could not borrow the receiver reference")
  log(collectionRef.getIDs())
  return collectionRef.getIDs()
}
`

export const addEmptyCollectionScript = `import ABD from 0xABDProfile
// This transaction sets up an account to use ABD
// by storing an empty moment collection and creating
// a public capability for it

transaction {

  prepare(acct: AuthAccount) {

    // First, check to see if a moment collection already exists
    if acct.borrow<&ABD.Collection>(from: /storage/ABDMomentCollection) == nil {

      // create a new ABD Collection
      let collection <- ABD.createEmptyCollection() as! @ABD.Collection

      // Put the new Collection in storage
      acct.save(<-collection, to: /storage/ABDMomentCollection)

      // create a public capability for the collection
      acct.link<&{ABD.MomentCollectionPublic}>(/public/ABDMomentCollection, target: /storage/ABDMomentCollection)
    }
  }
}
`

export const setExists = async (id: string | number) => {
  console.log('Runnin Set Exists Query', id)
  try {
    const res = await flowQuery(GetSetNameCDC, (arg, t) => [
      arg(String(id), fclTypes.UInt32),
    ])
    if (!res) return false
    return true
  } catch (err) {
    return false
  }
}

export const seriesExists = async (id: string | number) => {
  console.log('Runnning Series Exists Query', id)
  try {
    const res = await flowQuery(GetSetSeriesCDC, (arg, t) => [
      arg(String(id), fclTypes.UInt32),
    ])
    if (!res) return false
    return true
  } catch (err) {
    return false
  }
}

export const playExists = async (id: string | number) => {
  console.log('Running Play Exists Query', id)
  try {
    const res = await flowQuery(GetPlayMetadataCDC, (arg, t) => [
      arg(String(id), fclTypes.UInt32),
    ])
    console.log({ res })
    if (!res) return false
    return true
  } catch (err) {
    return false
  }
}

export const getNextPlayId = async () => {
  return Number(await flowQuery(GetNextPlayIDCDC))
}

export const getNextSetId = async () => {
  return Number(await flowQuery(GetNextSetIDCDC))
}

export const getSetName = async (id: number | string) => {
  return await flowQuery(GetNextSetNameCDC, () => [
    fcl.arg(id.toString(), fclTypes.UInt32),
  ])
}

export const getNextSeriesId = async () => {
  return Number(await flowQuery(GetNextSeriesIDCDC))
}
export const getCurrentSetId = async () => {
  const nextSetId = await getNextSetId()
  return nextSetId - 1
}

import GetCurrentSeriesCDC from './queries/get_current_series.cdc'
export const getCurrentSeries = async () => {
  return Number(await flowQuery(GetCurrentSeriesCDC))
}

import GetNextMomentIdCDC from './queries/get_next_moment_id.cdc'
export const getNextMomentId = async () => {
  console.log('Getting next moment id')
  try {
    return Number(await flowQuery(GetNextMomentIdCDC))
  } catch (err) {
    console.log('================================')
    throw err
  }
}

import GetPlaysNextSerialNumberCDC from './queries/get_plays_next_serialNumber.cdc'
export const getPlaysNextSerialNumber = async (
  setID: string | number,
  playID: string | number,
) => {
  return await flowQuery(GetPlaysNextSerialNumberCDC, () => [
    fcl.arg(setID, fclTypes.UInt32),
    fcl.arg(playID, fclTypes.UInt32),
  ])
}

import GetNumMomentsInEditionCDC from './queries/get_num_moments_in_edition.cdc'
export const getNumMomentsInEdition = async (
  setID: string | number,
  playID: string | number,
) => {
  return await flowQuery(GetNumMomentsInEditionCDC, () => [
    fcl.arg(setID, fclTypes.UInt32),
    fcl.arg(playID, fclTypes.UInt32),
  ])
}

import GetAllPlaysCDC from './queries/get_all_plays.cdc'
export const getAllPlays = async () => {
  return await flowQuery(GetAllPlaysCDC, () => [])
}

import GetPlayMetadataCDC from './queries/get_play_metadata.cdc'
export const getPlayMetadata = async (playID: string | number) => {
  return await flowQuery(GetPlayMetadataCDC, () => [
    fcl.arg(playID, fclTypes.UInt32),
  ])
}

export const borrowMoment = async (momentId: string | number) => {
  return await flowQuery(BorrowMomentCDC, () => [
    fcl.arg(String(momentId), fclTypes.UInt64),
  ])
}

const wait = (time: number) =>
  new Promise((res) => setTimeout(() => res(null), time))
