import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { FCC } from '~/interfaces'

export const Link: FCC<LinkProps> = ({
  children,
  noAnchor = false,
  onClick,
  target = '_self',
  ...props
}) => {
  if (noAnchor) {
    return <NextLink {...props}>{children}</NextLink>
  }

  return (
    <NextLink {...props}>
      <a className={props.className} target={target} onClick={onClick}>
        {children}
      </a>
    </NextLink>
  )
}

export interface LinkProps extends NextLinkProps {
  noAnchor?: boolean
  className?: string
  target?: string
}
