import styles from './legal-modal-links.module.scss'
import { withClassNames } from 'styles'
const cx = withClassNames(styles)

export const TermsAndConditions = ({
  hide,
  showButton = true,
}: {
  hide: () => void
  showButton: boolean
}) => (
  <div className={cx('modal-text-container')}>
    <p className={cx('py-5')}>Last Updated: February 10, 2022</p>
    <p>
      <strong>
        PLEASE READ THIS AGREEMENT CAREFULLY. IT AFFECTS YOUR RIGHTS AND WILL
        HAVE A SUBSTANTIAL IMPACT ON HOW DISPUTES BETWEEN YOU AND ABD
        COLLECTIBLES ARE RESOLVED. BY USING THIS SITE OR OBTAINING AN INTEREST
        IN ANY COLLECTIBLE ORIGINALLY DISTRIBUTED VIA THIS SITE, YOU ACCEPT THE
        TERMS OF THIS AGREEMENT.
      </strong>
    </p>
    <p className={cx('py-5')}>
      The ABDCollectibles.com site (the “Website” or “Site”) is part of an
      overall service (the “Service” or “Services”) operated by ABD Collectibles
      LLC, a California limited liability company (“ABD”, “we”, “us” or “our”).
      The Service (including the Website) provides individual users (“End User”,
      “User” or “You”) with features and services that include allowing you to
      buy, collect, and resell digital collectibles. The Service keeps records
      of purchases using a digital blockchain.
    </p>
    <p>
      Before you use the Services (including the Website), you must agree to
      these “Terms of Service” (the “Agreement”), our Privacy Policy at ABD
      Collectibles LLC, and for any purchases you make, the License Agreement at
      ABDcollectibles.com
    </p>
    <p>
      We regularly update and modify the Site. You agree that we may change the
      operation of the Site in our sole discretion, at any time and without
      giving notice. We may also modify this Agreement, the Privacy Policy, and
      the License Agreement at any time. We will post any amendments at
      ABDcollectibles.com, indicating the date the relevant agreement was last
      updated. Your continued use of the Service after the “Last Updated” date
      on any of these agreements will constitute your acceptance of and
      agreement to the changes. If you are not willing and able to comply with
      this Agreement as amended, you may not continue to use the Service.
    </p>
    <p>
      You may not use the Site if you are not at least 18 years old (or have not
      attained any greater applicable age of majority where you reside), do not
      agree to be bound by the terms of this Agreement, or your use of the Site
      would violate any laws or regulations that apply to you.
    </p>
    <h4 className={cx('py-5')}>1. Obtaining and Selling NFTs</h4>
    <p>
      <strong>Overview.</strong> The Site allows you to obtain, collect, and
      sell unique (non-fungible) tokens (each an “NFT”). Ownership of each NFT
      is linked to a specific license right in identified media segments
      (referred to as “Collectibles”) linked to the NFT, and those rights may be
      exercised by the owner of the associated NFT. The media segments making up
      the Collectibles are owned by entities (“Content Owners”) that have
      expressly licensed ABD to create and market NFTs related to those media
      segments. The specific rights in the Collectibles that are granted to you
      under ABD’s license from the Content Owners are defined and controlled by
      the License Agreement. Transfers of an NFT between parties are recorded on
      a blockchain to provide a verifiable record of ownership. When you own an
      NFT, provided you continue to abide by the terms of this Agreement and the
      License Agreement, you may showcase and view the Collectible associated
      with that NFT according to the terms of the License Agreement.
    </p>
    <p className={cx('py-5')}>
      <strong>Purchases, Sales and Trades.</strong> You may buy NFTs from ABD in
      the form of digital packs. Each pack contains a predetermined number of
      Collectibles, each associated with an individual NFT. The quantity of NFTs
      and the characteristics of the associated Collectibles are specified in
      the description of the pack posted on the Site. You may also buy
      individual NFTs from, sell NFTs to, and trade NFTs with other users in the
      “Marketplace” available on the Site. All purchases of digital packs are
      final and non-refundable.
    </p>
    <p>
      <strong>Purchases Outside the Website.</strong> Only those transfers of
      ownership made through the Service will be recognized by ABD as valid and
      recorded on the Flow blockchain. We reserve the right to disregard any
      claims of ownership other than those resulting from transfers processed
      through the Site in connection with user purchases of packs, or in the
      Marketplace. If you purchase NFTs other than in packs or through the
      Marketplace, such purchases are made at your own risk; there can be no
      assurance that you will receive or retain any rights associated with an
      NFT that someone purports to sell to you outside of ABD’s official
      channels.
    </p>
    <p className={cx('py-5')}>
      <strong>Earning NFTs.</strong> We may provide you with opportunities to
      receive free NFTs by participating in games, campaigns, or tasks posted on
      the Site from time to time.
    </p>
    <p>
      <strong>Marketplace.</strong> You agree to use due care when making
      purchases or posting NFTs for sale in the Marketplace. When you make an
      offer for sale that is accepted by another user, or you purchase an NFT
      offered by another user, you are entering into a binding legal contract
      with that user, to which ABD is a third-party beneficiary. All
      transactions in the Marketplace are final at the time payment is made,
      regardless of any party’s mistake. Any offers for sale that you post in
      the Marketplace are irrevocable when they are accepted by another user and
      the user has submitted valid payment. Any purchases that you make in the
      Marketplace are irrevocable and non- refundable when you submit your
      payment information and click to complete the purchase. When an NFT
      transfer record is submitted to the Flow Network, it is digitally“
      recorded on the blockchain and there is no mechanism to delete the
      transfer record or otherwise reverse the transfer.
    </p>
    <p className={cx('py-5')}>
      <strong>Resale Fee.</strong> ABD collects from the seller’s proceeds a six
      percent (6%) fee for each user-to-user sale of an NFT in the Marketplace.
      This fee may be changed from time to time in ABD’s sole discretion without
      prior notice and changes will be effective when posted on the Website.
    </p>
    <p>
      <strong>General Terms.</strong> These terms apply to any purchases you
      make using the Service(s):
    </p>
    <li className={cx('py-5')}>
      The selling prices of specific NFTs, and the cost of any other Services
      offered for which there is a charge, are posted on the Website.
    </li>
    <li>
      You agree to provide current, complete, and accurate purchase and account
      information for all purchases made via the Website and Services. You
      further agree to promptly update account and payment information,
      including email address, payment method, and payment card expiration date,
      so that we can complete your transactions and contact you as needed. We
      bill you through an online billing account for purchases made via the
      Website. Sales tax will be added to the price of purchases as we deem
      appropriate. We may change prices at any time. All payments shall be in
      U.S. dollars.
    </li>
    <li className={cx('py-5')}>
      You agree to pay all charges or fees at the prices then in effect for your
      purchases, and you authorize us to charge your chosen payment provider for
      any such amounts upon making your purchase. If a purchase is subject to
      recurring charges, then you consent to our charging your payment method on
      a recurring basis without requiring your prior approval for each recurring
      charge, until you notify us of your cancellation.
    </li>
    <li>
      ABD reserves the right to correct any errors or mistakes in pricing when
      it sells NFTs in digital packs or provides other Services, even if ABD has
      already requested or received payment. ABD also reserves the right to
      refuse any order placed through the Services.
    </li>
    <h4 className={cx('py-5')}>2. Accounts and Transaction Processing</h4>
    <p>
      <strong>Registration.</strong> When you create an account on the Site, you
      must provide complete, accurate registration information, and you must
      keep your information (particularly including your email address) updated
      when changes occur.
    </p>
    <p className={cx('py-5')}>
      <strong>Security.</strong>You are responsible for maintaining the security
      of your account, including selecting a unique, secure password and keeping
      your password confidential.
    </p>
    <p>
      <strong>Wallet.</strong> To purchase and store NFTs, you will need a
      digital wallet that is supported by the Service. Currently the Service
      supports the Blocto wallet provided by portto Co., Ltd (available at
      https://blocto.portto.io/en/ ). By using the Blocto wallet, you are bound
      by the terms and conditions for use of the wallet posted on the portto.io
      website. The creation, maintenance, and operation of the wallet are
      transactions directly between you and portto, and they are solely
      responsible for the wallet and for those transactions with you. ADB is not
      a party to, or responsible for, any aspect of the creation, maintenance,
      operation, or security of your wallet.
    </p>
    <p className={cx('py-5')}>
      <strong>Blockchain.</strong> When you purchase, sell, or receive an NFT on
      the Site, the transfer of ownership that takes place is managed and
      confirmed via the FlowTM blockchain network (the “Flow Network”)
      (https://www.onflow.org/). Your Blocto wallet is connected to operate with
      the Flow Network. Whenever you receive or transfer an NFT on the Site, the
      transfer will be recorded on the Flow blockchain in connection with your
      Flow Network public address. Your public address and NFT information will
      be publicly visible.
    </p>
    <h4>3. Taxes</h4>
    <p className={cx('py-5')}>
      We may collect sales or use tax on transactions performed on the Site if
      you are in the United States of America and we determine that we are
      required to collect such taxes on behalf of any U.S. state.
    </p>
    <p>
      You must notify us in writing before purchasing any NFTs if you are a
      resident of any other jurisdiction that will impose sales, use,
      value-added, or other taxes, assessments, and duties based on your
      purchase of NFTs or other use of the Services (“Taxes”). For the avoidance
      of doubt, ABD’s income taxes are not considered Taxes for this purpose.
    </p>
    <p className={cx('py-5')}>
      It is solely your responsibility to assess, collect, report, or remit the
      correct tax to the proper tax authority. You acknowledge that we, or our
      designated service providers, may make certain reports to tax authorities
      regarding transactions processed using the Services. If any Taxes are due
      based on your use of the Services, you will pay those taxes or reimburse
      us if we are required to pay them.
    </p>
    <h4>4. Benefits and Risks of Acquiring NFTs and Collectibles</h4>
    <p className={cx('py-5')}>
      Acquiring, showcasing, and trading Collectibles represented by NFTs is a
      hobby that many people enjoy. It is important to understand that being a
      collector (whether one collects stamps, coins, figurines, or media rights
      represented by NFTs) is subject to risks. By using the Service, you
      acknowledge and accept the following:
    </p>
    <li>
      NFTs are offered on the Site for collecting, for displaying to friends and
      acquaintances, and for trading as a hobby. They are provided only for
      consumer enjoyment, use and consumption and not as investments. Our NFTs
      are not “securities” as defined under the amended versions of the
      Securities Act of 1933, Securities Exchange Act of 1934, and Investment
      Company Act of 1940, or under the securities laws of any U.S. state.
    </li>
    <li className={cx('py-5')}>
      By purchasing NFTs using the Service, you represent that you are able,
      without impairing your financial condition, to bear the economic risk of
      being unable to recover the amounts you choose to spend purchasing NFTs.
    </li>
    <li>
      The value of NFTs representing collectible digital assets is subjective;
      the license rights they represent may have value to collectors for
      personal interest, historical or other reasons but have no inherent value.
    </li>
    <li className={cx('py-5')}>
      Market prices for NFTs are subject to significant volatility; factors
      beyond our control can result in price changes that adversely affect the
      value of your NFTs. There can be no assurance that any NFTs will retain
      their original value, or any expectation that their value will necessarily
      increase.
    </li>
    <li>
      Regulations for virtual currencies, NFTs and blockchain technology are in
      a state of uncertainty and ongoing development. New government actions,
      interpretations, and policies may affect the operation of the Site, and
      the use and value of your NFTs.
    </li>
    <li className={cx('py-5')}>
      There are inherent risks associated with using Internet-based storage and
      payment mechanisms and recording ownership data on a blockchain. Records
      of NFT ownership are not stored or controlled by the Site; ownership and
      transfers of NFTs are recorded on the supporting blockchain. Risks include
      hardware, software and network failures or errors, and malicious actions
      by other parties, including unauthorized access to information stored in
      relation to your account and fraudulently accessing or transferring your
      NFTs.
    </li>
    <h4>5. Intellectual Property Rights</h4>
    <p className={cx('py-5')}>
      <strong>Ownership and Copyright Notice.</strong> Our Website, media
      segments that make up the Collectibles, and all associated content
      provided as part of the Service(s) is the property of (or licensed by) ABD
      and is protected by U.S. and international copyright, trade dress,
      trademark, and/or other applicable laws. The look and feel of the Websites
      and Service(s) are also Copyright 2022, ABD Collectibles LLC, with all
      rights reserved.
    </p>
    <p>
      <strong>Trademarks and Service Marks.</strong> The trademarks and service
      marks used on the Site are the property of ABD or our business affiliates.
      You acknowledge our exclusive right to use those of the trademarks and
      service marks used herein that belong to ABD, and you agree not to use or
      seek to register any of our trademarks or service marks (or any
      confusingly similar marks) anywhere in the world.
    </p>
    <p className={cx('py-5')}>
      <strong>Limited License to Access the Website.</strong> ABD grants to you
      a limited, revocable, non-exclusive license to view the Website, including
      files and images contained in or generated by the Website in ordinary use,
      its look-and-feel, visual design elements, and accompanying data
      (collectively, “Software”), for personal use only and not for commercial
      resale, and only during the term of this Agreement and while you comply
      with each of the provisions herein.
    </p>
    <p>
      <strong>Exclusion.</strong> ABD does not grant you any title, interest, or
      rights other than the foregoing limited license in any element of the
      Software or Services, content, computer code, data, Media, or any other
      property whatsoever--except for the express, limited Media rights you
      receive under the License Agreement upon purchase of an NFT. You may not
      sell, redistribute, or reproduce any element of the Services, nor may you
      decompile, reverse-engineer, disassemble, or otherwise convert any part of
      the Services to a human-perceivable form.
    </p>
    <p className={cx('py-5')}>
      <strong>NFT.</strong> Upon purchasing an NFT from ABD or through the
      Marketplace in compliance with this Agreement, you own the NFT. You have
      the right to trade, sell, or give away an NFT that you legitimately
      acquire. The blockchain record in the Flow Network identifies the recorded
      ownership of the NFT. We recognize only transfers of ownership made
      through the Marketplace available on the Website; persons claiming title
      to an NFT based on other purported transfers are not considered “owners”
      for purposes of this Agreement. We will not take control of, freeze, or
      otherwise modify the ownership record of any NFT, unless we determine in
      our sole discretion that an NFT was wrongfully acquired, was acquired by
      violating a provision of this Agreement, or that the NFT’s License
      Agreement has been materially breached. In such cases we have the right
      to, but are not obligated to, act in response to such wrongful acts as we
      deem fair and appropriate, in our sole discretion.
    </p>
    <p>
      <strong>License Agreement.</strong> Each NFT is associated with a specific
      Collectible. Owning the NFT does not mean you “own” the associated
      Collectible. Ownership of the underlying media segments in a Collectible
      is retained by the Content Owners; under the License Agreement, the owner
      of the NFT is given specific rights to access, display and/or perform the
      media segments comprising the associated Collectible. All rights and
      obligations under the License Agreement convey with the NFT; therefore, if
      you sell trade or give away the NFT, all your rights and obligations under
      the License Agreement will be automatically transferred to the new owner.
      Any new owner must comply with the provisions of the License Agreement as
      a condition of holding the NFT and the associated license rights.
    </p>
    <h4 className={cx('py-5')}>6. User Content</h4>
    <p>
      We are not obligated to pre-screen material posted or submitted by users
      (“Content”). We may, but have no obligation to, remove Content that
      violates our policies or this Agreement.
    </p>
    <p className={cx('py-5')}>
      Any information you enter on the Website or upload (“Content”) remains
      yours. However, Content posted in your profile, messages you send, and
      information you post will be available to users of the Site and is
      therefore not confidential. Also, by posting, uploading, displaying,
      performing, transmitting, or otherwise distributing Content to the
      Service, you are granting ABD, its affiliates, officers, directors,
      employees, consultants, agents, and representatives a perpetual,
      royalty-free, paid-up, sublicensable, transferable, irrevocable,
      non-exclusive, worldwide license to use, copy, modify, create derivative
      works of, display, perform, publish, distribute, translate, and reformat,
      in any form, medium or manner, any text, information, data, materials,
      images, or other content you provide to us using the Services or submit or
      post to the Site worldwide. You further authorize ABD and its affiliates
      to use, reuse, and to grant others the right to use and reuse, your
      Content, and any reproduction or simulation thereof, in any form of media
      or technology now known or hereafter developed, both during and after your
      use of the Service(s), for any purposes related to the Service(s). You
      will not be compensated for any Content. By posting Content on the
      Service(s), you warrant and represent that you own the rights to the
      Content or are otherwise authorized to post the Content and grant the
      licenses herein.
    </p>
    <p>
      You will not upload, download, display, perform, transmit, or otherwise
      distribute any information or content in violation of any party&apos;s
      copyrights, trademarks, patents, or trade secrets. You are solely
      responsible for any violations of any laws and for any infringements of
      rights caused by any content you provide, post, or transmit, or that is
      provided or posted or transmitted using your account.
    </p>
    <p className={cx('py-5')}>
      If you submit comments, ideas, or suggestions about the Service, including
      without limitation suggestions for improvements, you agree that we are
      free to use and disclose such comments, ideas, and suggestions without
      additional compensation to you. You hereby grant us a perpetual,
      irrevocable, paid up, royalty free, nonexclusive, sublicensable,
      transferable, worldwide license to implement your submitted comments,
      ideas, and suggestions and/or use them for any other purpose.
    </p>
    <h4>7. Infringement Claims - Digital Millennium Copyright Act</h4>
    <p className={cx('py-5')}>
      ABD takes copyright infringement seriously. Pursuant to the Digital
      Millennium Copyright Act (Title 17 USC 512(c)), a copyright owner or their
      agent may submit a takedown notice to ABD. As a service provider, ABD is
      entitled to claim immunity from infringement claims pursuant to the “safe
      harbor” provisions of the DMCA.
    </p>
    <p>
      ABD makes a good faith effort to identify any repeat infringers. Those
      that violate ABD’s internal repeat infringer policy will have their
      accounts terminated.
    </p>
    <p className={cx('py-5')}>
      <strong>Infringement Claims</strong>
    </p>
    <p>
      To submit an infringement claim to ABD you must provide a notice including
      the following information:
    </p>
    <li className={cx('py-5')}>
      Identification of the copyrighted work claimed to have been infringed.
    </li>
    <li>
      Identification of the infringing material to be removed, and information
      reasonably sufficient to permit ABD to locate the material. Please submit
      the screen name of the user who posted the infringing material, and
      identify the message, posting or other location of infringing material.
    </li>
    <li className={cx('py-5')}>
      Contact information including your name, physical address, email address,
      and phone number.
    </li>
    <li>
      A statement that the complaining party has a good faith belief that the
      use of the material is unauthorized.
    </li>
    <li className={cx('py-5')}>
      A statement that the information in the notification is accurate, and,
      under penalty of perjury, that the complaining party is authorized to act
      on behalf of the copyright owner.
    </li>
    <li>
      A physical or electronic signature of the copyright owner (or authorized
      representative).
    </li>
    <p className={cx('py-5')}>
      Civil damage penalties, including costs and attorney fees, may apply
      against any person who knowingly and materially misrepresents certain
      information in a notification of infringement.
    </p>
    <p>Send all takedown notices to info@abdcollectibles.com.</p>
    <p className={cx('py-5')}>
      Please note that the identity and information in any copyright
      infringement claim we receive may be communicated to the alleged
      infrfinger.
    </p>
    <p>
      <strong>Disputing a Take Down</strong>
    </p>
    <p className={cx('py-5')}>
      If you have received a notice that your Content was taken down because of
      a copyright infringement claim, you may provide us with a counter
      notification in an effort to have the material in question restored to the
      Service(s). The notification must be sent in writing to
      info@abdcollectibles.com and must contain the following elements (see 17
      USC 512(g)(3)):
    </p>
    <li>
      A description of the material that has been taken down and the original
      location of the material before it was taken down.
    </li>
    <li className={cx('py-5')}>
      A statement under penalty of perjury that you have a good faith belief
      that the material was removed or disabled because of mistake or
      misidentification of the material.
    </li>
    <li>
      Your name, address, and telephone number, and a statement that you consent
      to the jurisdiction of the federal district court for the judicial
      district in which the address is located (or if you are outside of the
      United States, that you consent to jurisdiction of any judicial district
      in which the service provider may be found), and that the you will accept
      service of process from the person or company who provided the original
      infringement notification.
    </li>
    <li className={cx('py-5')}>Your physical or electronic signature.</li>
    <h4>8. General Account Terms</h4>
    <p className={cx('py-5')}>
      As a condition of access to the Service(s), you agree that you will:
    </p>
    <li>
      Be a human 18 years or older who has the legal capacity to enter a
      contract. Accounts registered or operated by minors, “bots” or other
      automated methods are not permitted.
    </li>
    <li className={cx('py-5')}>
      Provide your full legal name and valid email address where applicable, and
      all other device function access and information requested.
    </li>
    <li>
      Maintain the security of the device(s) you use to access the Service(s)
      and your account and password.
    </li>
    <li className={cx('py-5')}>
      Take full responsibility for: (i) all activity on your account, and (ii)
      anything posted or created under your account.
    </li>
    <li>
      Use the Service(s) only for legal and authorized purposes. You must not
      use the Service(s) in a way that violates any laws or regulations in your
      jurisdiction.
    </li>
    <p className={cx('py-5')}>
      As a condition of opening an account and ongoing access to the Service(s),
      You agree that you will NOT do any of the following or facilitate, enable,
      or assist any third party in doing any of the following:
    </p>
    <li>
      Violate ABD’s acceptable use policies (as determined in ABD’s sole
      discretion from time to time) for selection of your username and for all
      content, communications, and behavior relating to the Service. For
      example, physical abuse, violation of any party’s intellectual property
      rights, and communication with another user or any of our authorized
      representatives or customer service personnel that is profane, unlawful,
      threatening, abusive, libelous, defamatory, pornographic, obscene,
      ethnically or racially offensive, threatens abuse or retribution, contains
      slurs and/or is otherwise objectionable in our sole discretion, are
      grounds for immediately terminating your access to the Service.
    </li>
    <li className={cx('py-5')}>
      Create or operate another website, application, or service to falsely
      imply that it is associated with ABD Service(s).
    </li>
    <li>
      Reproduce, duplicate, copy, sell, resell, or exploit any portion of the
      Service(s) without ABD’s express written permission.
    </li>
    <li className={cx('py-5')}>
      Misrepresent your identity, create a new account, or use other accounts to
      avoid enforcement of any ABD policies, terms, or conditions.
    </li>
    <li>
      Disclose in any public posting the contact information of another account
      holder.
    </li>
    <li className={cx('py-5')}>
      Circumvent, disable, or otherwise interfere with security-related features
      of the Website, including features that prevent or restrict access to any
      Content or enforce limitations on the use of the Website and/or the
      Content contained therein.
    </li>
    <li>Engage in unauthorized framing of the Website.</li>
    <li className={cx('py-5')}>
      Trick, defraud, or mislead us and/or other users, especially in any
      attempt to learn sensitive account information such as user passwords.
    </li>
    <li>
      Make improper use of our support services or submit false reports of abuse
      or misconduct.
    </li>
    <li className={cx('py-5')}>
      Engage in any automated use of the system, such as using scripts to send
      comments or messages, or using any data mining, robots, or similar data
      gathering and extraction tools.
    </li>
    <li>
      Interfere with, disrupt, or create an undue burden on the networks or
      services connected to the Website and/or used to provide the Services.
    </li>
    <li className={cx('py-5')}>
      Attempt to impersonate another user or person or use the username of
      another user.
    </li>
    <li>Sell or otherwise transfer your account.</li>
    <li className={cx('py-5')}>
      Use any information obtained from the Website to harass, abuse, or harm
      another person.
    </li>
    <li>
      Use the Website as part of any effort to compete with us or otherwise use
      the Website and/or the Content for any revenue-generating endeavor or
      commercial enterprise, other than purchases and sales of digital
      collectibles in compliance with the terms of this Agreement.
    </li>
    <li className={cx('py-5')}>
      Decipher, decompile, disassemble, or reverse engineer any of the software
      comprising or in any way making up a part of the Website and/or Services.
    </li>
    <li>
      Attempt to bypass any measures of the Website designed to prevent or
      restrict access to the Website, or any system used to provide the
      Services.
    </li>
    <li className={cx('py-5')}>
      Harass, annoy, intimidate, or threaten any of our employees or agents
      engaged in providing any portion of the Website to you.
    </li>
    <li>
      Delete copyright or other proprietary rights notice from any Content.
    </li>
    <li className={cx('py-5')}>
      Copy, modify, adapt, hack, or interfere with the operation of the Website
      and/or Service(s).
    </li>
    <li>
      Upload or transmit (or attempt to upload or to transmit) viruses, Trojan
      horses, or other material, including excessive use of capital letters and
      spamming (continuous posting of repetitive text), that interferes with any
      party’s uninterrupted use and enjoyment of the Services or modifies,
      impairs, disrupts, alters, or interferes with the use, features,
      functions, operation, or maintenance of the Services.
    </li>
    <li className={cx('py-5')}>
      Upload or transmit (or attempt to upload or to transmit) any material that
      acts as a passive or active information collection or transmission
      mechanism, including without limitation, clear graphics interchange
      formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices
      (sometimes referred to as “spyware” or “passive collection mechanisms” or
      “pcms”).
    </li>
    <li>
      Except as may be the result of standard search engine or Internet browser
      usage, use, launch, develop, or distribute any automated system, including
      without limitation, any spider, bot, cheat utility, scraper, or offline
      reader that accesses the Services, or using or launching any unauthorized
      script or other software.
    </li>
    <li className={cx('py-5')}>
      Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
      Services.
    </li>
    <p>
      If we believe, in our sole discretion, that you have violated any
      provisions of this Agreement, we reserve all of the following rights: (1)
      to take legal action ourselves, (2) to refer any matter to police agencies
      and/or government regulators, (3) to cooperate in our sole discretion with
      law enforcement agencies and courts (whether they request information or
      compel its production by subpoena or other court order), (4) to provide
      information in our sole discretion regarding any user to government
      agencies, law enforcement personnel, and courts, including our identity
      records relating to the user, and (5) to void or reverse any transaction
      and to confiscate any NFTs involved in the transaction, if any aspect of
      the transaction or your actions violated any provision of this Agreement.
    </p>
    <h4 className={cx('py-5')}>9. Cancellation and Termination</h4>
    <p>
      You can cancel your account at any time by logging into your account or
      contacting us using the contact information provided below. If you are
      unsatisfied with our services, please email us at
      info@abdcollectibles.com.
    </p>
    <p className={cx('py-5')}>
      ABD may refuse to provide you with an account, or suspend or terminate
      your account, at any time in its sole discretion, with or without a
      reason, and without prior notice. We will not be liable to you or to any
      other party for such refusal, suspension, or termination. Following
      suspension or termination of your account, we retain all of our legal
      rights under this Agreement (in law and equity).
    </p>
    <p>
      Upon termination of your account for any reason, your account may be
      deactivated and/or deleted. You may no longer be able to access your
      account or the Site, access any information you have posted on the Site,
      or view media using the Site. We may delete your Collectibles’ images and
      descriptions (and the associated Media) from the Site. Cancellation or
      termination of your account will not entitle you to any refunds for
      Collectibles or anything else you purchased through your account. However,
      you will retain ownership of any Collectibles that you have legitimately
      purchased in compliance with applicable law and all provisions of this
      Agreement.
    </p>
    <h4 className={cx('py-5')}>10. Disclaimers and Limitations of Liability</h4>
    <p>
      ABD endeavors to provide reliable access to the Website. However, our
      Services are subject to various modes of failure and loss of data, some of
      which are beyond ABD’s control. Further, because user information,
      passwords, and other key data are transmitted over publicly accessible
      networks (such as mobile data communications networks and the internet) as
      well as being stored on servers connected to these networks, there is an
      inherent risk that this information will be intercepted or improperly
      accessed by third party actors.
    </p>
    <p className={cx('py-5')}>
      Your use of the Service(s) is at your own risk, and on an “as-is” and
      “as-available” basis. You assume the risk of using the Blocto wallet; we
      do not warrant, endorse, guarantee, or assume responsibility for any
      product or service offered by a third party. You assume the risk of
      conducting transactions over the Internet and agree that we have no
      liability for security breaches unless we are grossly negligent.
    </p>
    <p>
      You agree that you have had whatever opportunity you deem necessary to
      investigate the Services, and the laws, rules, or regulations that may be
      applicable to NFT transactions and that you are not relying upon any
      statement of law or fact made by ADB relating to the legality of these
      transactions.
    </p>
    <p className={cx('py-5')}>
      To the full extent permitted by law, ABD and its Content Owners hereby
      exclude all express and implied warranties, including (without limitation)
      implied warranties of non-infringement, merchantability, fitness for a
      particular purpose, non-infringement, correctness, accuracy, or
      reliability.
    </p>
    <p>
      We do not warrant that the information we provide or that is provided by
      wallet or blockchain providers is accurate, reliable, or correct; that the
      Services will meet your requirements; that the Services will be available
      at any time or location; that the Services will function in an
      uninterrupted manner or be secure; that any defects or errors will be
      corrected; or that the services are free of viruses and other harmful
      components. Any subject matter downloaded or otherwise obtained through
      the Service is downloaded at your own risk – you are solely responsible
      for any damage to your property or loss of data that results from such
      download. We make no representations or warranties about the time required
      to complete the processing of a transaction.
    </p>
    <li className={cx('py-5')}>
      Information or keywords you provide while using the Service(s).
    </li>
    <li>Your use of or inability to use Service(s).</li>
    <li className={cx('py-5')}>
      Loss of NFTs and/or loss of access to Collectibles in the Services.
    </li>
    <li>Your failure to secure your device(s) and your account information.</li>
    <li className={cx('py-5')}>
      Interception of data or other unauthorized access to data by a third
      party.
    </li>
    <li>
      Any modification, price change, suspension, or discontinuance of the
      Service(s).
    </li>
    <li className={cx('py-5')}>
      Viruses or other malicious software, glitches, bugs, errors or
      inaccuracies resulting from access to the Service(s).
    </li>
    <li>Damage to your device(s) while using the Service(s).</li>
    <li className={cx('py-5')}>
      The behavior, actions, and inactions of other users of the Service(s).
    </li>
    <li>
      Actions taken by ABD in response to violations of the Terms of Service,
      Privacy Policy, License Agreement and ABD’s other policies, as amended
      from time to time.
    </li>
    <li className={cx('py-5')}>
      Any effects of changes in ABD operations, policies and Terms of Service on
      you or any business you conduct, including without limitation the need to
      modify your practices to comply with such changes.
    </li>
    <li>
      The technical operating and transmission features of the Service(s),
      including without limitation the transfer of unencrypted content over
      various networks.
    </li>
    <li className={cx('py-5')}>
      Acts (or failures to act) of ABD’s third-party hardware, software, and
      network service providers.
    </li>
    <p>
      Each exclusion and limitation of our liability stated herein applies to
      all forms of damages or losses, whether direct, indirect, incidental,
      special, consequential, or exemplary, and including but not limited to,
      damages for loss of profits, goodwill, reputation, use, cost of procuring
      substitute goods or services, personal injury, diminution of value or any
      other intangible losses (even if ABD has been advised of the possibility
      of such damages).
    </p>
    <p className={cx('py-5')}>
      Some jurisdictions restrict the disclaimer of warranties and/or exclusion
      of damages, so such disclaimers and exclusions may not apply to you.
    </p>
    <p>
      You acknowledge and agree that we have relied on the representations,
      warranties, disclaimers, and limitations of liability in this agreement
      (which reflect a reasonable and fair allocation of risk between the
      parties and form an essential part of the bargain) in entering this
      Agreement and allowing you to access the Site. We would be unable to
      provide the Site without these provisions.
    </p>
    <p className={cx('py-5')}>
      If despite the provisions herein, ABD is found to be liable for damages to
      you or another party, our aggregate liability is limited to the greater of
      (i) the amount you actually paid us for the Services within the three
      months preceding the event causing the claimed liability, and (ii) $100.
    </p>
    <h4>11. Indemnification</h4>
    <p className={cx('py-5')}>
      You will defend, indemnify, and hold harmless ABD, its Content Owners, and
      their respective shareholders, members, directors, officers, employees,
      attorneys, agents, representatives, suppliers and contractors
      (collectively, “Indemnified Parties”) from any claim, demand, lawsuit,
      action, proceeding, investigation, liability, damage, loss, cost or
      expense, including without limitation reasonable attorneys’ fees, arising
      out or relating to (a) your use of, or conduct in connection with, the
      Services; (b) any User Content you provide; (c) your violation of this
      Agreement, the Privacy Policy, the License Agreement, or any other policy
      established by ABD; or (d) your violation of any applicable law or the
      rights of any other person or entity. If you are obligated to indemnify
      any Indemnified Party, ABD (or, at ABD’s discretion, the applicable
      Indemnified Party) will have the right, in its sole discretion, to control
      any action or proceeding and to determine whether the Indemnified Party
      wishes to settle, and if so, on what terms.
    </p>
    <h4>12. External Links</h4>
    <p className={cx('py-5')}>
      Hyperlinks to other websites or resources (“Linked Sites”) are provided as
      a convenience and for informational purposes only; they do not constitute
      an endorsement or an approval by ABD of any of the products, services or
      opinions offered on Linked Sites. ABD bears no responsibility for the
      accuracy, legality or content of any Linked Site or for that of subsequent
      links. You acknowledge that we are not liable for any loss you incur
      relating to Linked Sites or your use of or reliance on their content.
    </p>
    <h4>13. Force Majeure</h4>
    <p className={cx('py-5')}>
      ABD will have no responsibility or liability for any failure or delay in
      performance of any Services, or any loss or damage that you may incur, due
      to any circumstance or event beyond the control of ABD, including without
      limitation any pandemic, flood, extraordinary weather conditions,
      earthquake, or other act of God, fire, act of war, terrorist act,
      insurrection, riot, labor dispute, accident, action of government,
      communications, power failure, or equipment or software malfunction.
    </p>
    <h4>14. Adults Only</h4>
    <p className={cx('py-5')}>
      If you are under the age of 18, you may not use the Site. The Site is not
      intended for children under the age of 18 and ABD does not knowingly
      collect information from minors. We will close the account of any user
      under the age of 18 or who is otherwise not of legal age to use the Site.
    </p>
    <h4>15. Privacy Policy</h4>
    <p className={cx('py-5')}>
      Please refer to our Privacy Policy for information on how we collect, use,
      and share your information. The Privacy Policy is hereby incorporated into
      and forms a part of this Agreement. You agree to the collection, use,
      retention, and disclosure of your information as provided in the Privacy
      Policy.
    </p>
    <h4>16. Disputes and Arbitration Procedures</h4>
    <p className={cx('py-5')}>
      Any claim or dispute arising between you and ABD that relates in any way
      to this Agreement, your use of or access to the Service(s), actions by ABD
      or its agents, or any transaction through the Service(s), will be resolved
      exclusively through final and binding arbitration, rather than in court.
      Any judgment on the award rendered by the arbitrator may be entered in any
      court of competent jurisdiction. The parties agree that they will not
      appeal any arbitration decision to any court.
    </p>
    <p>
      As an alternative, you may file a claim in small claims court, if your
      claims qualify and so long as the matter remains in such court and
      advances only on an individual (non-class, non- representative) basis.
    </p>
    <p className={cx('py-5')}>
      Equitable Remedies: Notwithstanding the foregoing, ABD may seek and obtain
      injunctive relief in any court of competent jurisdiction.
    </p>
    <p>
      <strong>
        YOU AND ABD MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL
        BASIS AND NOT IN A CLASS ACTION OR OTHER REPRESENTATIVE STATUS. THE
        ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON&apos;S OR
        PARTY&apos;S CLAIMS OR PRESIDE OVER ANY CONSOLIDATED, REPRESENTATIVE, OR
        CLASS PROCEEDING. THE ARBITRATOR MAY AWARD RELIEF ONLY TO THE INDIVIDUAL
        PARTY SEEKING RELIEF FOR THAT PARTY’S INDIVIDUAL CLAIMS. YOU EXPRESSLY
        WAIVE ANY RIGHT TO A JURY TRIAL.
      </strong>
    </p>
    <p className={cx('py-5')}>
      <strong>Arbitration Procedure</strong>
    </p>
    <p>
      The arbitration will be conducted by the American Arbitration Association
      (“AAA”) under its Consumer Arbitration Rules as modified by this
      Agreement. The AAA&apos;s rules are available at www.adr.org.
    </p>
    <p className={cx('py-5')}>
      A party who intends to seek arbitration must first send to the other a
      Notice of Dispute (“Notice”) describing the nature and basis of the claims
      the party is asserting and the relief sought. A Notice to ABD must be sent
      by certified mail to ABD Collectibles LLC, Attn: Notice of Dispute, 65
      West Easy Street #102, Simi Valley, CA 93065. ABD will send any Notice to
      the email address on file for your ABD account; it is your responsibility
      to keep your email address up to date.
    </p>
    <p>
      If you and ABD are unable to resolve the claims in the Notice within 30
      days after the Notice is sent, either party may initiate arbitration
      proceedings. A form for initiating arbitration proceedings is available at
      www.adr.org. In addition to filing this form with the AAA in accordance
      with its rules and procedures, the party initiating the arbitration must
      mail a copy of the completed form to the opposing party. You may send a
      copy to ABD at the following address: 65 West Easy Street #102, Simi
      Valley, CA 93065. If ABD initiates an arbitration it will send a copy of
      the completed form to the email address on file for your ABD account.
      Payment of all filing, administration and arbitrator fees will be governed
      by the AAA&apos;s rules. Settlement offers shall not be disclosed to the
      arbitrator.
    </p>
    <p className={cx('py-5')}>
      Any arbitration hearings shall be held in Los Angeles, California. If the
      value of the relief sought is $1,000 or less, either you or ABD may elect
      to have the arbitration conducted by telephone or based on written
      submissions.
    </p>
    <p>
      The arbitrator will decide all claims consistent with applicable law and
      will honor privileges recognized by law. The arbitrator shall not be bound
      by rulings in prior arbitrations involving other users. The
      arbitrator&apos;s award shall be final and binding; judgment on the award
      may be entered in any court having jurisdiction thereof.
    </p>
    <p className={cx('py-5')}>
      <strong>Opt-Out Procedure</strong>
    </p>
    <p>
      <strong>
        YOU CAN REJECT THE ARBITRATION REQUIREMENT HEREIN BY MAILING ABD A
        WRITTEN NOTICE POSTMARKED NO LATER THAN 30 DAYS AFTER THE DATE YOU OPEN
        YOUR ACCOUNT FOR THE FIRST TIME.
      </strong>
    </p>
    <p className={cx('py-5')}>
      The Notice must include your name, street address, city, state and zip
      code, and the user ID(s) and email address(es) associated with the ABD
      account(s) to which the opt-out applies and must be signed by you. You
      must mail the Opt-Out notice to 65 West Easy Street #102, Simi Valley, CA
      93065. This is the only way to opt out of arbitration; the rest of this
      Agreement will continue to apply. Opting out of arbitration as provided in
      this version of the Agreement has no effect on any other or future
      arbitration agreements you may have with ABD.
    </p>
    <h4>17. General Terms</h4>
    <p className={cx('py-5')}>
      If the arbitration provisions above are found not to apply to your claim,
      the state courts located in Los Angeles, California and the Federal courts
      located in Los Angeles, California will have exclusive jurisdiction, and
      you agree to submit to the personal jurisdiction of these courts.
    </p>
    <p>
      You agree that regardless of any statute or law to the contrary, any claim
      or cause of action arising out of or related to use of the Site, this
      Agreement, or the Privacy Policy must be filed within one (1) year after
      such claim or cause of action arose or be forever barred.
    </p>
    <p className={cx('py-5')}>
      This Agreement, the Privacy Policy, the License Agreement, and all
      policies posted through the Service(s) constitute the entire agreement
      between you and ABD and supersede any prior agreements between you and ABD
      (including, but not limited to, any prior versions of this Agreement). We
      may assign our rights under this Agreement in our sole discretion; you may
      not assign or transfer any of your rights under this Agreement other than
      as provided herein, and any other attempted assignment or transfer of
      rights by you shall be void and have no effect. The failure of ABD to
      exercise or enforce any of its rights shall not constitute a waiver of
      those rights. The laws of the State of California, without regard to
      principles of conflict of laws, will govern this Agreement and any claim
      or dispute between you and ABD. Headings are for reference purposes only
      and do not limit the scope or extent of any section. If any provision of
      this Agreement is held to be invalid or unenforceable, such provision
      shall be struck out and the remaining provisions will remain in force. The
      provisions of this Agreement which by their nature are intended to survive
      termination of this Agreement will survive its termination. The Content
      Owners from which rights in Collectibles are obtained under this Agreement
      are intended beneficiaries of this Agreement and may enforce against you
      their rights in their content, and under this Agreement and the License
      Agreement. There are no other intended third- party beneficiaries of this
      Agreement.
    </p>
    {showButton && (
      <button onClick={hide} className={cx('close-button')}>
        Close
      </button>
    )}
  </div>
)
