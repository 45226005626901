import Router, { useRouter } from 'next/router'
import { useEffect } from 'react'

export const OPACITY_EXIT_DURATION = 0.2
const IGNORED_ROUTES = [
  '_error',
  '404',
  '500',
  'payment/success',
  'reveal-pack',
  // 'my-collection',
  // 'unopenedPacks',
  'waiting-room',
]

const routeChange = () => {
  const tempFix = () => {
    const elements = document.querySelectorAll('style[media="x"]')
    elements.forEach((elem) => elem.removeAttribute('media'))
    setTimeout(() => {
      elements.forEach((elem) => elem.remove())
    }, OPACITY_EXIT_DURATION * 1000)
  }
  tempFix()
}

export const usePageTransitionFix = () => {
  const router = useRouter()
  if (process.env.NODE_ENV === 'development') {
    console.debug(
      'WARNING: Still using FOUC temp fix on route change.  Has the Next.js bug not been fixed?  See https://github.com/vercel/next.js/issues/17464',
    )
  }
  useEffect(() => {
    Router.events.on('routeChangeComplete', routeChange)
    Router.events.on('routeChangeStart', routeChange)

    return () => {
      Router.events.off('routeChangeComplete', routeChange)
      Router.events.off('routeChangeStart', routeChange)
    }
  }, [])

  // TODO: Greg figure out a way to cache query params when masking paths using as
  useEffect(() => {
    if (IGNORED_ROUTES.find((path) => router.pathname.includes(path))) return
    router.replace(router.pathname, router.asPath)
    // ? Use replace() instead of push()?
  }, [router.pathname, router.asPath])
}
