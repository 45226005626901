import type { CurrentUserObject } from '@onflow/fcl'
import { Session } from 'next-auth'
import { useSession } from 'next-auth/react'
import { createContext, useContext } from 'react'

export interface IAuthContextUser extends CurrentUserObject {
  id: string
}

export interface IAuthContext {
  currentUser: IAuthContextUser
  session: ReturnType<typeof useSession>
  login: () => Promise<IAuthContextUser>
  logout: () => void
  signIn: () => Promise<IAuthContextUser>
  signOut: () => void
}

export const defaultCurrentUser: IAuthContextUser = {
  id: '',
  f_type: '',
  f_vsn: '',
  services: [],
}

export const AuthContext = createContext<IAuthContext>({
  currentUser: defaultCurrentUser,
  session: { status: 'loading', data: null },
  login: async () => defaultCurrentUser,
  logout: () => {},
  signIn: async () => defaultCurrentUser,
  signOut: () => {},
})

export const useAuth = () => useContext(AuthContext)
