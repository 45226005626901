import React from 'react'
import { motion, Variants } from 'framer-motion'

import type { FCC } from '~/interfaces'

export const PageTransitions: FCC<PageTransitionsProps> = ({ children }) => {
  const pageVariants = {
    init: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  }

  return (
    <motion.div
      variants={pageVariants}
      initial='init'
      animate='enter'
      exit='leave'
    >
      {children}
    </motion.div>
  )
}

export interface PageTransitionsProps {}

export const BounceInOutAnimation = {
  init: { opacity: 0, scale: 0 },
  enter: {
    opacity: 1,
    scale: [0.8, 1.15, 1],
    transition: { duration: 0.5, damping: 15.3, type: 'spring' },
  },
  leave: { opacity: 0, scale: 0, transition: { duration: 0.42 } },
}

export const HeaderAnimationVariants: Variants = {
  enter: {
    transition: {
      delayChildren: 0.3,
    },
  },
}

export const PaymentAnimation: Variants = {
  enter: {
    transition: {
      staggerChildren: 0.3,
    },
  },
}

export const SlideInFromRightAnimation: Variants = {
  init: { opacity: 0, x: '100%' },
  enter: { opacity: 1, x: 0, transition: { damping: 16.3, type: 'spring' } },
  leave: { opacity: 0, x: '100%' },
}

export const SlideInFromLeftAnimation: Variants = {
  init: { opacity: 0, x: '-100%' },
  enter: { opacity: 1, x: 0, transition: { damping: 16.3, type: 'spring' } },
  leave: { opacity: 0, x: '-100%' },
}

export const SlideInFromTopAnimation: Variants = {
  init: { opacity: 0, y: '-100%' },
  enter: { opacity: 1, y: 0, transition: { damping: 14.4, type: 'spring' } },
  leave: {
    opacity: 0,
    y: '-100%',
    transition: { damping: 15, type: 'spring' },
  },
}

export const SlideInFromBottomAnimation: Variants = {
  init: { opacity: 0, y: '100%' },
  enter: { opacity: 1, y: 0, transition: { damping: 14.2, type: 'spring' } },
  leave: { opacity: 0, y: '100%', transition: { damping: 15, type: 'spring' } },
}

export const PackSelectionCopyAnimation: Variants = {
  init: { opacity: 0, y: 100 },
  enter: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.75, damping: 8, type: 'spring' },
  },
  leave: { opacity: 0, scale: 0 },
}

export const GradientBoxAnimation: Variants = {
  init: { opacity: 0, y: 100 },
  enter: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.65, damping: 10, type: 'spring' },
  },
  leave: { opacity: 0, scale: 0 },
}

export const FadeIn: Variants = {
  init: { opacity: 0 },
  enter: { opacity: 1, transition: { duration: 1.3 } },
  leave: { opacity: 0 },
}

export const ModalAnimation: Variants = {
  open: {
    opacity: 1,
    scale: 1,
    transition: { type: 'spring', duration: 0.8 },
  },
  closed: {
    scale: 0,
    opacity: 0,
  },
}

export const ModalBackgroundAnimation: Variants = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
  },
}

export const MyCollectionsListAnimation: Variants = {
  enter: {
    transition: {
      staggerChildren: 0.1,
    },
  },
}

export const RevealMomentAnimation: Variants = {
  init: { opacity: 0, scale: 0 },
  enter: {
    opacity: 1,
    scale: [0.8, 1.15, 1],
    transition: { duration: 0.5, damping: 15.3, type: 'spring' },
  },
  leave: {
    opacity: 0,
  },
}

export const CommunityHeaderAnimantion: Variants = {
  init: { opacity: 0, x: '-100%' },
  enter: { opacity: 1, x: 0, transition: { damping: 15.8, type: 'spring' } },
  leave: { opacity: 0, scale: 0 },
}
