import styles from './legal-modal-links.module.scss'
import { withClassNames } from 'styles'
const cx = withClassNames(styles)

export const PrivacyPolicy = ({
  hide,
  showButton = true,
}: {
  hide: () => void
  showButton: boolean
}) => (
  <div className={cx('modal-text-container')}>
    <h1 className={cx('text-center')}>PRIVACY POLICY</h1>
    <h2 className={cx('text-center')}>ABD Collectibles LLC (“ABD”)</h2>
    <h4 className={cx('py-5')}>ACCEPTANCE OF THE PRIVACY POLICY</h4>
    <p>
      This Privacy Policy describes our policies and procedures on the
      collection, use and disclosure of Your information when You use the
      Service. This Privacy Policy informs You about Your privacy rights and how
      the law protects You.
    </p>
    <p className={cx('py-5')}>
      We use Your Personal Data to provide and improve the Service. By using the
      Service, You agree to the collection and use of information in accordance
      with this Privacy Policy.
    </p>
    <h3>INTERPRETATION AND DEFINITIONS</h3>
    <h4 className={cx('py-5')}>Interpretation</h4>
    <p>
      The words of which the initial letter is capitalized have meanings defined
      under the following conditions. The following definitions shall have the
      same meaning regardless of whether they appear in singular or in plural.
    </p>
    <h4 className={cx('py-5')}>Definitions</h4>
    <p>For the purposes of this Privacy Policy:</p>
    <li>
      You means the individual accessing or using the Service, or the company,
      or other legal entity on behalf of which such individual is accessing or
      using the Service, as applicable.
    </li>
    <li className={cx('py-5')}>
      Company (referred to as either “ABD”, “the Company”, “We”, “Us” or “Our”
      in this Agreement) refers to ABD Collectibles LLC, a California limited
      liability company{' '}
    </li>
    <li>
      Affiliate means an entity that controls, is controlled by or is under
      common control with a party, where “control” means ownership of 50% or
      more of the shares, equity interest or other securities entitled to vote
      for election of directors or other managing authority.
    </li>
    <li className={cx('py-5')}>
      Account means a unique account created for You to access our Service or
      parts of our Service.
    </li>
    <li>Website refers to ABDCollectibles.com</li>
    <li className={cx('py-5')}>
      Service refers to the provision of Non-Fungible Tokens (“NFTs”)
      representing specific limited rights in designated media segments, the
      Website, and any and all other services the Company currently offers or
      will offer in the future.
    </li>
    <li>Country refers to: United States</li>
    <li className={cx('py-5')}>
      Service Provider means any natural or legal person who processes the data
      on behalf of the Company. It refers to third-party companies or
      individuals employed by the Company to facilitate the Service, to provide
      the Service on behalf of the Company, to perform services related to the
      Service or to assist the Company in analysing how the Service is used.
    </li>
    <li>
      Personal Data is any information that relates to an identified or
      identifiable individual.
    </li>
    <li className={cx('py-5')}>
      Cookies are small files that are placed on Your computer, mobile device or
      any other device by a website, containing the details of Your browsing
      history on that website among its many uses.
    </li>
    <li>
      Device means any device that can access the Service such as a computer, a
      cell phone or a digital tablet.
    </li>
    <li className={cx('py-5')}>
      Usage Data refers to data collected automatically, either generated by the
      use of the Service or from the Service infrastructure itself (for example,
      the duration of a page visit).
    </li>
    <h3>COLLECTING AND USING YOUR PERSONAL DATA</h3>
    <h4 className={cx('py-5')}>Types of Data Collected</h4>
    <h4>Personal Data</h4>
    <p className={cx('py-5')}>
      While using Our Service, We may ask You to provide Us with certain
      personally identifiable information that can be used to contact or
      identify You. Personally identifiable information may include, but is not
      limited to:
    </p>
    <li>First name and last name</li>
    <li className={cx('py-5')}>Phone number</li>
    <li>Address, State, Province, ZIP/Postal code, City</li>
    <li className={cx('py-5')}>Usage Data</li>
    <li>Date of Birth</li>
    <li className={cx('py-5')}>
      Financial Information such as bank account information like
      account/routing number, debit/credit card number, and more
    </li>
    <h4>Usage Data</h4>
    <p className={cx('py-5')}>
      Usage Data is collected automatically when using the Service.
    </p>

    <p>Usage Data may include information such as:</p>
    <li className={cx('py-5')}>
      Online identifiers such as Your Device&apos;s Internet Protocol address
      (e.g. IP address)
    </li>
    <li>Type of Device</li>
    <li className={cx('py-5')}>Browser type </li>
    <li>Browser version</li>
    <li className={cx('py-5')}>The pages of our Service that You visit</li>
    <li>The time and date of Your visit</li>
    <li className={cx('py-5')}>The time spent on those pages</li>
    <li>Device Information such as hardware, operating system</li>
    <li className={cx('py-5')}>Geolocation Data </li>
    <p>
      We may also collect any information that Your browser sends whenever You
      visit our Service or when You access the Service by or through a Device.
    </p>
    <h4 className={cx('py-5')}>Tracking Technologies and Cookies</h4>
    <p>
      We use Cookies and similar tracking technologies to track the activity on
      Our Service and store certain information. Tracking technologies used are
      beacons, tags, and scripts to collect and track information and to improve
      and analyze Our Service.
    </p>
    <p className={cx('py-5')}>
      You can instruct Your browser to refuse all Cookies or to indicate when a
      Cookie is being sent. However, if You do not accept Cookies, You may not
      be able to use some parts of our Service.
    </p>
    <p>
      Cookies can be “Persistent” or “Session” Cookies. Persistent Cookies
      remain on your personal computer or mobile device when You go offline,
      while Session Cookies are deleted as soon as You close your web browser.
    </p>
    <h4 className={cx('py-5')}>Use of Your Personal Data</h4>
    <p>The Company may use Personal Data for the following purposes:</p>
    <li className={cx('py-5')}>
      To provide and maintain our Service, including monitoring the usage of our
      Service.
    </li>
    <li>
      To manage Your Account: to manage Your registration as a user of the
      Service. The Personal Data You provide can give You access to different
      functionalities of the Service that are available to You as a registered
      user.
    </li>
    <li className={cx('py-5')}>
      For the performance of a contract: the development, compliance and
      undertaking of the purchase contract for the products, items or services
      You have purchased or of any other contract with Us through the Service.
    </li>
    <li>
      To contact You: To contact You by email, telephone calls, SMS, or other
      equivalent forms of electronic communication, such as a mobile
      application&apos;s push notifications regarding updates or informative
      communications related to the functionalities, products or contracted
      services, including the security updates, when necessary or reasonable for
      their implementation.
    </li>
    <li className={cx('py-5')}>
      To provide You with news, special offers and general information about
      other goods, services and events which we offer that are similar to those
      that you have already purchased or enquired about unless You have opted
      not to receive such information.
    </li>
    <li>To manage Your requests: To attend and manage Your requests to Us.</li>
    <li className={cx('py-5')}>
      Optimize and enhance our services: We use your Personal Information to
      understand how our products and Services are being used to help us improve
      our Services and develop new products.
    </li>
    <li>To Detect and prevent fraud.</li>
    <li className={cx('py-5')}>To Provide you with customer support.</li>
    <li>
      Other business purposes: We may use your information for additional
      purposes if that purpose is disclosed to you before we collect the
      information or if we obtain your consent.
    </li>
    <p className={cx('py-5')}>
      We may share your personal information in the following situations:
    </p>
    <li>
      With Service Providers: We may share Your personal information with
      Service Providers so that they can provide us with services, including
      identity verification, fraud detection and prevention, security threat
      detection, payment processing, customer support, data analytics,
      Information Technology, advertising, marketing, data processing, network
      infrastructure, storage, and transaction monitoring.
    </li>
    <li className={cx('py-5')}>
      For Business transfers: We may share or transfer Your personal information
      in connection with, or during negotiations of, any merger, sale of Company
      assets, financing, or acquisition of all or a portion of our business to
      another company.
    </li>
    <li>
      With Affiliates: We may share Your information with Our affiliates, in
      which case we will require those affiliates to honor this Privacy Policy.
      Affiliates include Our parent company and any other subsidiaries, joint
      venture partners or other companies that We control or that are under
      common control with Us.
    </li>
    <li className={cx('py-5')}>
      With Business Partners: We may share Your information with Our business
      partners to offer You certain products, services or promotions.
    </li>
    <li>
      Professional Advisors. We may share your Personal Information with our
      professional advisors, including legal, accounting, or other consulting
      services for purposes of audits or to comply with our legal obligations.
    </li>
    <li className={cx('py-5')}>
      With other users: If You interact with other users, Your contacts may see
      information about you.
    </li>
    <h4>Retention of Your Personal Data</h4>
    <p className={cx('py-5')}>
      The Company will retain Your Personal Data only for as long as is
      necessary for the purposes set out in this Privacy Policy. We will retain
      and use Your Personal Data to the extent necessary to comply with our
      legal obligations (for example, if we are required to retain your data to
      comply with applicable laws), resolve disputes, and enforce our legal
      agreements and policies.
    </p>
    <p>
      The Company will also retain Usage Data for internal analysis purposes.
      Usage Data is generally retained for a shorter period of time, except when
      this data is used to strengthen the security or to improve the
      functionality of Our Service, or We are legally obligated to retain this
      data for longer time periods.
    </p>
    <h4 className={cx('py-5')}>Transfer of Your Personal Data</h4>
    <p>
      Your information, including Personal Data, is processed at the
      Company&apos;s operating offices and in any other places where the parties
      involved in the processing are located. It means that this information may
      be transferred to — and maintained on — computers located outside of Your
      state, province, country or other governmental jurisdiction where the data
      protection laws may differ than those from Your jurisdiction.
    </p>
    <p className={cx('py-5')}>
      Your consent to this Privacy Policy followed by Your submission of such
      information represents Your agreement to that transfer.
    </p>
    <p>
      The Company will take all steps reasonably necessary to ensure that Your
      data is treated securely and in accordance with this Privacy Policy and no
      transfer of Your Personal Data will take place to an organization or a
      country unless there are adequate controls in place including the security
      of Your data and other personal information.
    </p>
    <h4 className={cx('py-5')}>Other Disclosure of Your Personal Data</h4>
    <h4>Business Transactions</h4>
    <p className={cx('py-5')}>
      If the Company is involved in a merger, acquisition or asset sale, Your
      Personal Data may be transferred. We will provide notice to You that Your
      Personal Data may be transferred due to a business transaction and that
      You may become subject to a different Privacy Policy.
    </p>
    <h4>Law enforcement</h4>
    <p className={cx('py-5')}>
      Under certain circumstances, the Company may be required to disclose Your
      Personal Data if required to do so by law or in response to valid requests
      by public authorities (e.g. a court or a government agency).
    </p>
    <h4>Other legal requirements</h4>
    <p className={cx('py-5')}>
      The Company may disclose Your Personal Data in the good faith belief that
      such action is necessary to:
    </p>
    <li>Comply with a legal obligation</li>
    <li className={cx('py-5')}>
      Protect and defend the rights or property of the Company
    </li>
    <li>
      Prevent or investigate possible wrongdoing in connection with the Service
      and/or Your use of the Service
    </li>
    <li className={cx('py-5')}>
      Protect the personal safety of Users of the Service or the public
    </li>
    <li>Protect against legal liability</li>
    <h4 className={cx('py-5')}>Security of Your Personal Data</h4>
    <p>
      We work hard to protect the Company and you from unauthorized access to or
      unauthorized alteration, disclosure, or destruction of Personal
      Information we collect and store. Measures we take include encryption of
      Website communications with SSL; periodic review of our Personal
      Information collection, storage, and processing practices; and restricted
      access to your Personal Information on a need-to-know basis for our
      employees, contractors and agents who are subject to strict contractual
      confidentiality obligations and may be disciplined or terminated if they
      fail to meet these obligations.
    </p>
    <h4 className={cx('py-5')}>
      How You Can Access or Change Your Personal Data
    </h4>
    <p>
      The Company understands that you may want to change, access or delete your
      Personal Data. You may do so by contacting us via email at
      info@abdcollectibles.com. To protect your privacy and security, we will
      verify your identity before granting access or making changes to your
      Personal Data. Any request to access your Personal Data will be answered
      in less than thirty (30) days.
    </p>
    <h4 className={cx('py-5')}>What Are Your Privacy Rights</h4>
    <p>
      As explained above, we collect Personal Information in various ways
      depending upon your use of our Site. We process Personal Information on
      the following legal bases: (1) with your consent; (2) as necessary to
      perform our agreements; and (3) as necessary for our legitimate interests
      in providing services where those interests do not override your
      fundamental rights and freedom related to data privacy.
    </p>
    <p className={cx('py-5')}>
      Personal Information we collect may be transferred to, and stored and
      processed in, the United States or any other country in which we or our
      affiliates, partners, or service providers maintain facilities.
    </p>
    <p>
      If you are a resident of the European Union, European Economic Area,
      Channel Islands or Switzerland, you are entitled to the following rights.
      Please note, in order to verify your identity, we may require you to
      provide us with Personal Information prior to accessing any records
      containing information about you.
    </p>
    <p className={cx('py-5')}>
      The Right to Access: You have the right to obtain information about our
      processing of personal data and a copy of your personal data that we
      store.
    </p>
    <p>
      The Right to Rectify your Data. You have the right to request that we
      update your personal data if it is inaccurate or incomplete.
    </p>
    <p className={cx('py-5')}>
      The Right to Request Data Erasure: You have the right to have your
      Personal Information erased from our files if the data is no longer
      necessary for the purpose for which it was collected, you withdraw consent
      and no other legal basis for processing exists, or you believe your
      fundamental rights to data privacy and protection outweigh our legitimate
      interest in continuing the processing.
    </p>
    <p>
      The Right to Restrict or Object to Our Processing: You have the right to
      restrict or object to our processing if we are processing your data based
      on legitimate interests or the performance of a task in the public
      interest as an exercise of official authority (including profiling); using
      your data for direct marketing (including profiling); or processing your
      data for purposes of scientific or historical research and statistics.
    </p>
    <p className={cx('py-5')}>
      The Right to Lodge a Complaint: You have the right to lodge a complaint
      about our data collection and processing actions with your data protection
      authority. Contact details for data protection authorities can be found on
      this website.
    </p>
    <h4>Data Protection Officer</h4>
    <p className={cx('py-5')}>
      We have appointed a Data Protection Officer (DPO) who is responsible for
      overseeing questions in relation to this privacy policy. If you have any
      questions about this privacy policy, including any requests to exercise
      your legal rights, please contact the DPO using the details set out below.
    </p>
    <h4>Personal Data Management</h4>
    <p className={cx('py-5')}>
      According to the Privacy Policy, you agree to share your personal data
      with our Service. However, you still have the right to update, transfer or
      delete it from us.
    </p>
    <h4>Links to Other Websites</h4>
    <p className={cx('py-5')}>
      Our Service may contain links to other websites that are not operated by
      Us. If You click on a third party link, You will be directed to that third
      party&apos;s site. We strongly advise You to review the Privacy Policy of
      every site You visit.
    </p>
    <p>
      We have no control over and assume no responsibility for the content,
      privacy policies or practices/data protection practices of any third party
      sites or services.
    </p>
    <h4 className={cx('py-5')}>Changes to this Privacy Policy</h4>
    <p>
      We may update our Privacy Policy from time to time. We will notify You of
      any changes by posting the new Privacy Policy on this page and updating
      the “Last updated” date at the top of this Privacy Policy.
    </p>
    <p className={cx('py-5')}>
      You are advised to review this Privacy Policy periodically for any
      changes. Changes to this Privacy Policy are effective when they are posted
      on this page.
    </p>
    <h4>Age Requirement</h4>
    <p className={cx('py-5')}>
      Persons under the age of 18 are not eligible to use any Services.
    </p>
    <h4>Contact Us</h4>
    <p className={cx('py-5')}>
      If you have any questions about this Privacy Policy, You can contact us:
    </p>
    <li>
      By visiting this page on our website: Abdcollectibles.com/contact-us
    </li>
    {showButton && (
      <button
        onClick={hide}
        className={cx('gradient-button-pack gradient-button-4 p-10')}
      >
        Close
      </button>
    )}
  </div>
)
