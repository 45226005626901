import { createContext, useContext } from 'react'
import { InferQueryOutput, trpc } from '~/api/client'
import { FCC, IPack } from '~/interfaces'

export const PackTypeDetailsProvider: FCC = ({ children }) => {
  const packTypeDetails = trpc.useQuery(['soldPackData'])
  const data = packTypeDetails.data || defaultPackTypeDetails

  return (
    <PackTypeDetailsContext.Provider value={data}>
      {children}
    </PackTypeDetailsContext.Provider>
  )
}

export const defaultPackTypeDetails: IPackTypeDetails = {
  currentSet: {
    id: '',
    created_at: null,
    deleted_at: null,
    flow_id: null,
    name: null,
    updated_at: null,
  },
  packs: [],
  boxes: [],
}

export const PackTypeDetailsContext = createContext(defaultPackTypeDetails)
export const usePackTypeDetailsCtx = () => useContext(PackTypeDetailsContext)
export const usePackTypeDeailsSinglePack = (
  type: IPack['type'] | Lowercase<IPack['type'] | string>,
) => {
  const ctx = usePackTypeDetailsCtx()
  return [...ctx.packs, ...ctx.boxes].find(
    (pack) => pack.type.toLowerCase() === type.toLowerCase(),
  )
}

export type IPackTypeDetails = InferQueryOutput<'soldPackData'>

export const isValidPackTypeName = (name: string) =>
  ['premium', 'standard', 'premium-box', 'standard-box'].includes(
    name.toLowerCase(),
  )
