import { config } from '@onflow/fcl'

export const flowConfig = getConfig()
config()
  .put('accessNode.api', flowConfig.accessNode) // Configure FCL's Access Node
  .put('flow.network', flowConfig.network) // Configure FCL's Access Node
  .put('fcl.appDomainTag', flowConfig.appDomainTag)
  .put('challenge.handshake', flowConfig.walletHandshake) // Configure FCL's Wallet Discovery mechanism
  .put('0xABDProfile', flowConfig.abdProfile) // Will let us use `0xABDProfile` in our Cadence
  .put('0xNFTProfile', flowConfig.nftProfile) // Will let us use `0xNFTProfile` in our Cadence

// TODO: allow for env-var overrides
function getConfig(): IFlowConfig {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
    return mainNetConfig()
  }
  return testNetConfig()
}

function testNetConfig(): IFlowConfig {
  return {
    accessNode: 'https://rest-testnet.onflow.org',
    network: 'testnet',
    appDomainTag: 'ABDSOME-APP-V0.0-user',
    walletHandshake: 'https://flow-wallet-testnet.blocto.app/authn',
    abdProfile: '0x9a51cde893be7031',
    nftProfile: '0x631e88ae7f1d7c20',
  }
}

function mainNetConfig(): IFlowConfig {
  return {
    accessNode: 'https://rest-mainnet.onflow.org',
    network: 'mainnet',
    appDomainTag: 'ABDSOME-APP-V0.0-user',
    walletHandshake: 'https://flow-wallet.blocto.app/authn',
    abdProfile: '0x67af7ecf76556cd3',
    nftProfile: '0x1d7e57aa55817448',
  }
}

interface IFlowConfig {
  accessNode: string
  network: 'testnet' | 'mainnet'
  appDomainTag: string
  walletHandshake: string
  abdProfile: string
  nftProfile: string
}
