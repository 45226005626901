import Image, { ImageProps } from 'next/image'
import { IPack } from '~/interfaces'

export const imagePaths = {
  backgroundBigGlitch:
    'https://cdn.shopify.com/s/files/1/0519/9423/0953/files/background-big-glitch.jpg?v=1650260831',
  mikemoRaffleBackground: '/mo-giveaway.jpg',
  logoOfficialABD: '/ABD-logo-offical.png',
  mikemoExplosionDesktop: '/mikemo-explosion-desktop.jpg',

  prodCommonThumbnail: '/prod-thumbnail-common.jpg',
  prodRareThumbnail: '/prod-thumbnail-rare.jpg',
  prodVaultedRareThumbnail: '/prod-thumbnail-vaulted-rare.jpg',
  prodLegendaryThumbnail: '/prod-thumbnail-legendary.jpg',
  prodLegendaryRefractorThumbnail: '/prod-thumbnail-legendary-refractor.gif',

  toreyCommonThumbnail: '/torey-thumbnail-common.jpeg',
  toreyRareThumbnail: '/torey-thumbnail-rare.jpeg',
  toreyLegendaryRefractorThumbnail: '/torey-thumbnail-legendary-refractor.jpeg',
  toreySkatedDeck: '/torey-skated-deck.gif',
  toreyLegendaryRefractor: '/torey-legendary-refractor.gif',
  toreyBanner: '/series-3-banner-desktop.jpg',
  toreyBannerMobile: '/series-3-banner-mobile.jpg',

  jamieFoyBanner: '/jamie-foy-banner-desktop.jpg',
  jamieFoyBannerMobile: '/jamie-foy-banner-mobile.jpg',

  commonThumbnail: '/common-thumbnail.jpg',
  rareThumbnail: '/rare-thumbnail.jpg',
  vaultedRareThumbnail: '/vaultedRare-thumbnail.jpg',
  legendaryThumbnail: '/legendary-thumbnail.jpg',

  thGoldLegacy: '/th-gold-legacy.jpg',
  thBlackMosaic: '/th-black-mosaic.jpg',
  thRedKyber: '/th-red-kyber.jpg',
  thBlueLava: '/th-blue-lava.jpg',
  thSilverLava: '/th-silver-lava.jpg',
  thRainbowHolo: '/th-rainbow-holo.jpg',

  jawsGoldLegacy: '/jaws-gold-legacy.jpg',
  jawsBlackMosaic: '/jaws-black-mosaic.jpg',
  jawsRedKyber: '/jaws-red-kyber.jpg',
  jawsBlueLava: '/jaws-blue-lava.jpg',
  jawsRainbowHolo: '/jaws-rainbow-holo.jpg',

  jamieFoyGoldLegacy: '/jamie-foy-gold-legacy.jpg',
  jamieFoyBlackMosaic: '/jamie-foy-black-mosaic.jpg',
  jamieFoyRedKyber: '/jamie-foy-red-kyber.jpg',
  jamieFoyBlueLava: '/jamie-foy-blue-lava.jpg',
  jamieFoyRainbowHolo: '/jamie-foy-rainbow-holo.jpg',

  standardPackMikeMo: '/mikemoOfficial.png',
  premiumPackMikeMo: '/premiumPack.png',

  premiumPackProd: '/prod-pack-premium.gif',
  standardPackProd: '/prod-pack-standard.gif',

  prodLegendaryCard: '/prod-legendary-card.jpg',
  prodLegendaryRefractorCard: '/prod-refractor-card.jpg',
  soldOut: '/sold-out.png',
  raffleImg: '/ticket-generic.png',
  blackBox: '/sealed-black.jpg',
}

const sizes = {
  sm: {
    width: '50px',
    height: '25px',
  },
  md: {
    width: '100px',
    height: '50px',
  },
  lg: {
    width: '200px',
    height: '100px',
  },
}
type Sizes = keyof typeof sizes

export const ABDLogoOfficial = ({ size = 'md' }: { size?: Sizes }) => {
  const dimensions = sizes[size]
  return (
    <Image
      src={imagePaths.logoOfficialABD}
      layout='fixed'
      height={dimensions.height}
      width={dimensions.width}
      alt='ABD Logo'
      priority
    />
  )
}

export const StandardPackImage = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.standardPackMikeMo}
    layout={'responsive'}
    width={350}
    height={350}
    alt={'Standard Pack Image'}
    {...props}
  />
)

export const PremiumPackImage = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.premiumPackMikeMo}
    layout={'responsive'}
    width={350}
    height={350}
    alt={'Premium Pack Image'}
    {...props}
  />
)

export const SoldOutImage = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.soldOut}
    layout={'responsive'}
    width={'100%'}
    height={'100%'}
    alt={'Premium Pack Image'}
    {...props}
    className='sold-out'
  />
)

export const NFTThumbnail = ({
  type,
  ...props
}: { type: 'common' | 'rare' | 'vaultedRare' | 'legendary' } & Omit<
  ImageProps,
  'src'
>) => {
  const src = imagePaths[`${type}Thumbnail`]
  return (
    <Image
      src={src}
      layout={'responsive'}
      width={310}
      height={310}
      alt={src.replace('.jpg', '')}
      {...props}
    />
  )
}

export const PackImageMikeMo = ({ type }: { type: IPack['type'] }) => {
  if (type === 'Premium') {
    return <PremiumPackImage />
  }

  return <StandardPackImage />
}

export const PackImageProd = ({ type }: { type: IPack['type'] }) => {
  if (type === 'Premium') {
    return (
      <>
        <PremiumPackImage src={imagePaths.premiumPackProd} />
      </>
    )
  }

  return (
    <>
      <StandardPackImage src={imagePaths.standardPackProd} />
    </>
  )
}

export const MomentImage = ({ path }: { path: string }) => (
  // eslint-disable-next-line @next/next/no-img-element
  <img src={path} alt={'Moment Image'} />
)

export const RevealMomentImg = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.standardPackMikeMo}
    layout={'responsive'}
    width={350}
    height={350}
    alt={'Reveal moment Image'}
    {...props}
  />
)

export const RaffleImg = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.raffleImg}
    layout={'responsive'}
    width={500}
    height={303}
    alt={'Reveal moment Image'}
    {...props}
  />
)

export type ProdCardType = 'prodLegendary' | 'prodLegendaryRefractor'
export const ProdCards = ({
  type,
  ...props
}: { type: ProdCardType } & Omit<ImageProps, 'src'>) => {
  const src = imagePaths[`${type}Card`]
  return (
    <Image
      src={src}
      layout={'responsive'}
      width={'100%'}
      height={'100%'}
      alt={src.replace('.jpg', '')}
      {...props}
    />
  )
}

export const ProdNFTThumbnail = ({
  type,
  ...props
}: {
  type:
    | 'prodCommon'
    | 'prodRare'
    | 'prodVaultedRare'
    | 'prodLegendary'
    | 'prodLegendaryRefractor'
} & Omit<ImageProps, 'src'>) => {
  const src = imagePaths[`${type}Thumbnail`]
  return (
    <Image
      src={src}
      layout={'responsive'}
      width={310}
      height={310}
      alt={src.replace('.jpg', 'gif')}
      {...props}
    />
  )
}

export const ToreyNFTThumbnail = ({
  type,
  ...props
}: {
  type: 'toreyCommon' | 'toreyRare' | 'toreyLegendaryRefractor'
} & Omit<ImageProps, 'src'>) => {
  const src = imagePaths[`${type}Thumbnail`]
  return (
    <Image
      src={src}
      layout={'responsive'}
      width={310}
      height={310}
      alt={src.replace('.jpeg', '')}
      {...props}
    />
  )
}

export const ToreySkatedDeck = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.toreySkatedDeck}
    layout={'responsive'}
    width={350}
    height={350}
    alt={'Torey Pudwill Skated Deck from The Bigger Bang Video'}
    {...props}
  />
)

export const ToreyLegendaryRefractor = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.toreyLegendaryRefractor}
    layout={'responsive'}
    width={350}
    height={350}
    alt={'Torey Pudwill Legendary Refractor Trading Card'}
    {...props}
  />
)

export const ToreyBannerDesktop = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.toreyBanner}
    layout={'responsive'}
    width={350}
    height={350}
    alt={'Torey Pudwill Legendary Refractor Banner Desktop'}
    {...props}
  />
)
export const ToreyBannerMobile = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.toreyBannerMobile}
    layout={'fill'}
    width={1000}
    height={1417}
    alt={'Torey Pudwill Legendary Refractor Banner Mobile'}
    {...props}
  />
)

export const BlackBox = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.blackBox}
    layout={'responsive'}
    width={350}
    height={350}
    alt={'Mystery black box'}
    {...props}
  />
)

export const BlurryImage = ({ ...props }: Omit<ImageProps, 'src'>) => {
  const imagePage = BlurryImagePaths[0]
  return (
    <Image src={imagePage} layout={'fill'} alt={'Blurry Day 1'} {...props} />
  )
}

const BlurryImagePaths = [
  '/series-3-blurry/day-1.16d8ca7a-5b52-42fe-94a2-59510a681ed7.png',
  '/series-3-blurry/day-2.f2fa91b8-a0c3-407a-820a-dfe44fb7755c.png',
  '/series-3-blurry/day-3.1b195168-4b29-4a5d-a79c-66d776c34a44.png',
  '/series-3-blurry/day-4.4949d9f1-0f5b-4829-aeb3-0b1dd2ee9419.png',
  '/series-3-blurry/day-5.b3e2b5b0-28bc-4f61-8ff8-76cefdbf12a4.png',
  '/series-3-blurry/day-6.73d81548-dbb0-4326-8814-e2aa4193ccd1.png',
  '/series-3-blurry/day-7.c2f5d5e7-8083-41b7-a754-f33936bf72a8.png',
  '/series-3-blurry/day-8.56082f29-7859-4c26-8c24-88a942f01fa0.png',
  '/series-3-blurry/day-9.c9a25c95-3563-4a2a-a456-bd22a00f1971.png',
  '/series-3-blurry/day-10.17a73867-ba07-42c5-bb5a-17f1633ccc61.png',
  '/series-3-blurry/day-11.336bcf41-2e54-4940-b490-6d092d2feca0.png',
  '/series-3-blurry/day-12.8126e3c8-6223-4760-a159-ec340335cfae.png',
  '/series-3-blurry/day-13.2309cd31-8954-4c0b-98cc-4bec105de02d.png',
  '/series-3-blurry/day-14.a43a0cab-ac25-4a64-b1e4-7fba00bee341.png',
]

export const TonyCards = ({
  type,
  ...props
}: { type: 'RainbowHolo' | 'SilverLava' | 'BlueLava' | 'RedKyber' | 'BlackMosaic' | 'GoldLegacy' } & Omit<
  ImageProps,
  'src'
>) => {
  const src = imagePaths[`th${type}`]
  return (
    <Image
      src={src}
      layout={'responsive'}
      width={310}
      height={310}
      alt={src.replace('.jpg', '')}
      {...props}
    />
  )
}

export const JawsCards = ({
  type,
  ...props
}: { type: 'RainbowHolo' | 'BlueLava' | 'RedKyber' | 'BlackMosaic' | 'GoldLegacy' } & Omit<
  ImageProps,
  'src'
>) => {
  const src = imagePaths[`jaws${type}`]
  return (
    <Image
      src={src}
      layout={'responsive'}
      width={310}
      height={310}
      alt={src.replace('.jpg', '')}
      {...props}
    />
  )
}

export const JamieFoyCards = ({
  type,
  ...props
}: { type: 'RainbowHolo' | 'BlueLava' | 'RedKyber' | 'BlackMosaic' | 'GoldLegacy' } & Omit<
  ImageProps,
  'src'
>) => {
  const src = imagePaths[`jamieFoy${type}`]
  return (
    <Image
      src={src}
      layout={'responsive'}
      width={310}
      height={310}
      alt={src.replace('.jpg', '')}
      {...props}
    />
  )
}

export const JamieFoyBannerDesktop = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.jamieFoyBanner}
    layout={'responsive'}
    width={350}
    height={350}
    alt={'Jamie Foy 1 of 1 Gold Legacy card with his skated truck inside'}
    {...props}
  />
)

export const JamieFoyBannerMobile = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.jamieFoyBannerMobile}
    layout={'fill'}
    width={1000}
    height={1417}
    alt={'Jamie Foy 1 of 1 Gold Legacy card with his skated truck inside'}
    {...props}
  />
)