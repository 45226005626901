import { GrowthBook, JSONValue } from '@growthbook/growthbook'
import { useFeature } from '@growthbook/growthbook-react'

const API_KEY = getApiKeyFromConfig()

const GROWTHBOOK_ENDPOINT = 'https://cdn.growthbook.io/api/features/' + API_KEY

export const growthbook = new GrowthBook({
  url: GROWTHBOOK_ENDPOINT,
  // Callback when a user is put into an A/B experiment
  // trackingCallback: (experiment, result) => {
  //   console.log('Experiment Viewed', {
  //     experimentId: experiment.key,
  //     variationId: result.variationId,
  //   })
  // },
})

let interval
if (!interval && typeof window !== 'undefined') {
  interval = setInterval(() => {
    updateFeatures()
  }, 2500)
}

let cache = false
export async function setDeviceId() {
  const res = await fetch('/api/ip')
  const data = await res.json()
  growthbook.setAttributes({
    ...growthbook.getAttributes(),
    deviceId: data.ip,
  })
}

export async function updateFeatures(useCache = false) {
  if (cache && useCache) return
  const res = await fetch(GROWTHBOOK_ENDPOINT)
  const data = await res.json()
  growthbook.setFeatures(data.features)
  cache = true
  return data
}

export const getFlag = async (name: FFlags) => {
  await updateFeatures()
  return growthbook.isOn(name)
}

export const getValue = async <T extends JSONValue>(
  name: FFlags,
  defaultValue: T,
) => {
  await updateFeatures()
  return growthbook.getFeatureValue(name, defaultValue)
}

export const getPurchaseButtonDelayTime = () =>
  getValue(FFlags.purchase_button_delay_time, 0)

export const getSoldOutFFlag = () => getFlag(FFlags.sold_out)

export const useSaleEnded = () => useFeature(FFlags.sale_ended)

export const getFF = async <Type extends string | number | boolean = boolean>(
  flag: `${FFlags}`,
  defaultValue: Type,
) => {
  await updateFeatures()
  return growthbook.getFeatureValue(flag, defaultValue)
}
export const useFF = <Type extends string | number | boolean = boolean>(
  flag: `${FFlags}` | string,
) => useFeature<Type>(flag)

export enum FFlags {
  'prod_release' = 'prod_release',
  'purchase_button_delay_time' = 'purchase_button_delay_time',
  'sold_out' = 'sold_out',
  'limit_packs_can_buy' = 'limit_packs_can_buy',
  'sale_ended' = 'sale_ended',
  'boxes_premium_available' = 'boxes_premium_available',
  'boxes_standard_available' = 'boxes_standard_available',
  'buy_now_open' = 'buy_now_open',
  'countdown_date' = 'countdown_date',
  'countdown_on' = 'countdown_on',
  'packs_premium_available' = 'packs_premium_available',
  'packs_standard_available' = 'packs_standard_available',
  'series_3_released' = 'series_3_released',
  'waiting_room_on' = 'waiting_room_on',
  'display_waiting_videos' = 'display_waiting_videos',
  'display_mailchimp' = 'display_mailchimp',
}

function getApiKeyFromConfig() {
  const env = process.env.NEXT_PUBLIC_VERCEL_ENV

  switch (env) {
    case 'development':
      return 'key_deve_207c15b437a84ddd'
    case 'preview':
      return 'key_prev_5de28f05a4c056a8'
    case 'production':
    default:
      return 'key_prod_70d5c1687db732b3'
  }
}
