import * as fcl from '@onflow/fcl'
import { createContext, useCallback, useContext } from 'react'
import { FCC } from '~/interfaces'
import './config'
// import { defaultInitialUser, useUser } from './useCurrentUser.hook'
import { AuthContext, useUser } from '~/auth/client'

const AuthedState = () => {
  return (
    <div>
      <button onClick={fcl.unauthenticate}>Log Out</button>
    </div>
  )
}

const UnauthenticatedState = () => {
  return (
    <div>
      <button onClick={fcl.logIn}>Log In</button>
      <button onClick={fcl.signUp}>Sign Up</button>
    </div>
  )
}

export const FlowAuthButton = () => {
  const { currentUser } = useUser()
  if (currentUser.loggedIn) return <AuthedState />

  return <UnauthenticatedState />
}

// export const AuthContext = createContext({
//   currentUser: defaultInitialUser,
//   signIn: async () => defaultInitialUser,
//   signOut: async () => {},
// })
// export const AuthProvider: FCC = ({ children }) => {
//   const { currentUser } = useUser()
//   const signIn = async () => fcl.logIn()
//   const signOut = async () => fcl.unauthenticate()

//   return (
//     <AuthContext.Provider
//       value={{
//         currentUser,
//         signIn,
//         signOut,
//       }}
//     >
//       {children}
//     </AuthContext.Provider>
//   )
// }

export const useAuth = () => useContext(AuthContext)
