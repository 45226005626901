import styles from './legal-modal-links.module.scss'
import { withClassNames } from 'styles'
const cx = withClassNames(styles)

import { Modal } from 'components'
import { useState } from 'react'
import { TermsAndConditions } from './TermsAndConditions'
import { PrivacyPolicy } from './PrivacyPolicy'
import { LicenseAgreement } from './LicenseAgreement'

export const LegalModalLinks = () => {
  return (
    <span className={cx('container')}>
      <p>
        I agree to the <TermsAndConditionsModalLink />
        <PrivacyPolicyModalLink />
        <LicenseAndAgreementModalLink />
      </p>
    </span>
  )
}

const TermsAndConditionsModalLink = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <a className={cx('link')} onClick={() => setOpen(true)}>
        terms and conditions,{' '}
      </a>
      <Modal modalOpen={open} onClose={() => setOpen(false)}>
        <div className={cx('modal-content')}>
          <TermsAndConditions hide={() => setOpen(false)} />
        </div>
      </Modal>
    </>
  )
}
const PrivacyPolicyModalLink = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <a className={cx('link')} onClick={() => setOpen(true)}>
        privacy policy{' '}
      </a>
      <Modal modalOpen={open} onClose={() => setOpen(false)}>
        <div className={cx('modal-content')}>
          <PrivacyPolicy hide={() => setOpen(false)} />
        </div>
      </Modal>
    </>
  )
}
const LicenseAndAgreementModalLink = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <a className={cx('link')} onClick={() => setOpen(true)}>
        and license agreement
      </a>
      <Modal modalOpen={open} onClose={() => setOpen(false)}>
        <div className={cx('modal-content')}>
          <LicenseAgreement hide={() => setOpen(false)} />
        </div>
      </Modal>
    </>
  )
}
