import classNames, { Argument } from 'classnames'

export const withClassNames = <Styles extends StylesBase = {}>(
  styles: Styles,
) => {
  type ClassNameKey = TypedString<keyof Styles>

  type ClassName = LiteralUnion<ClassNameKey, Argument>
  return (...args: ClassName[]) => {
    const classes = args.map((className: any) => {
      if (className in styles) {
        return styles[className]
      }
      return className
    })

    return classNames(classes)
  }
}
export interface StylesBase extends Record<string, string> {}

type LiteralUnion<T extends U, U = string> = T | (U & { zz_IGNORE_ME?: never })
type TypedString<StringValues extends string> = LiteralUnion<
  StringValues,
  string
>
