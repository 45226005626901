import { createReactQueryHooks } from '@trpc/react'
import type { AppRouter } from '.'
import { withTRPC } from '@trpc/next'
import superjson from 'superjson'

const url = '/api/trpc' // don't specify a domain here so it default's to the browser's

// ℹ️ Type-only import:
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-8.html#type-only-imports-and-export

/**
 * A set of strongly-typed React hooks from your `AppRouter` type signature with `createReactQueryHooks`.
 * @link https://trpc.io/docs/react#3-create-trpc-hooks
 */
export const trpc = createReactQueryHooks<AppRouter>()

export const TrpcAppWrapper = (MyApp: any) =>
  withTRPC<AppRouter>({
    config({ ctx }) {
      /**
       * If you want to use SSR, you need to use the server's full URL
       * @link https://trpc.io/docs/ssr
       */
      return {
        url,
        transformer: superjson,
        headers: async () => {
          if (ctx?.req) {
            // on ssr, forward client's headers to the server
            return {
              ...ctx.req.headers,
              'x-ssr': '1',
            }
          }
          return {}
        },
        /**
         * @link https://react-query.tanstack.com/reference/QueryClient
         */
        // queryClientConfig: { defaultOptions: { queries: { staleTime: 60 } } },
      }
    },
    /**
     * @link https://trpc.io/docs/ssr
     */
    ssr: false,
  })(MyApp)

export * from './trpc-helpers'
