import { FC, PropsWithChildren } from 'react'
import s from './gradient.module.scss'

export const GradientBox: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div className={s['gradientbox-parent-container']}>
      <div className={s['gradientbox-container']}>{children}</div>
    </div>
  )
}
