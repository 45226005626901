// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

if (
  process.env.VERCEL_ENV !== 'development' &&
  process.env.NODE_ENV !== 'development'
) {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://19526bf35f88473f98cba7714dbaedb9@o1235293.ingest.sentry.io/6521601',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    environment: process.env.VERCEL_ENV,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
}
