import { IMoment } from '~/api/myCollection'

const videoPaths = {
  abdTrailerCompressed: `/prod-main-trailer.mp4`,
  prodSkatedShoes: '/prod-skated-shoe.mp4',
  prodOneOfOneShoe: '/shoe-one-of-one.mp4',
  prodMainTrailer: '/prod-main-trailer.mp4',
  prodRaffle: '/prod-raffle-ad.mp4',
  toreySkatedDeck: '/torey-skated-deck.gif',
  toreyLegendaryRefractor: '/torey-legendary-refractor.gif',
  toreyMainTrailer: '/torey-main-trailer.mp4',
  toreySkatedDeckVideo: '/torey-skated-deck.mp4',
  toreyLegendaryRefractorVideo: '/torey-tangible-card.mp4',
  tonyHawkReveal: '/tony-hawk-cyber-monday.mp4',
  tonyHawkStory: '/th-reveal.mp4',
  tonyHawkVaulted: '/tony-hawk-vaulted.mp4',
  jawsReveal: '/jaws-reveal.mp4',
}

export const JawsReveal = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.jawsReveal}
    >
      <source src={videoPaths.jawsReveal} type='video/mp4' />
    </video>
  )
}

export const TonyHawkVaulted = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.tonyHawkVaulted}
    >
      <source src={videoPaths.tonyHawkVaulted} type='video/mp4' />
    </video>
  )
}

export const TonyHawkReveal = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.tonyHawkReveal}
    >
      <source src={videoPaths.tonyHawkReveal} type='video/mp4' />
    </video>
  )
}

export const TonyHawkStory = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.tonyHawkStory}
    >
      <source src={videoPaths.tonyHawkStory} type='video/mp4' />
    </video>
  )
}

export const ABDVideoTrailer = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.abdTrailerCompressed}
    >
      <source src={videoPaths.abdTrailerCompressed} type='video/mp4' />
    </video>
  )
}

export const MomentVideo = ({ moment }: { moment: IMoment }) => (
  <video loop controls playsInline width={'100%'} height={'100%'}>
    <source src={moment?.plays?.video_url!} type='video/mp4' />
  </video>
)

export const ProdSkatedShoe = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.prodSkatedShoes}
    >
      <source src={videoPaths.prodSkatedShoes} type='video/mp4' />
    </video>
  )
}

export const ProdOneOfOneShoe = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.prodOneOfOneShoe}
    >
      <source src={videoPaths.prodOneOfOneShoe} type='video/mp4' />
    </video>
  )
}

export const ProdRaffleVideo = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.prodRaffle}
    >
      <source src={videoPaths.prodOneOfOneShoe} type='video/mp4' />
    </video>
  )
}

export const ProdMainTrailer = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.prodMainTrailer}
    >
      <source src={videoPaths.prodMainTrailer} type='video/mp4' />
    </video>
  )
}

// TODO: make this reused by the components above
export const Video = ({ src }: { src: string }) => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={src}
    >
      <source src={src} type='video/mp4' />
    </video>
  )
}

export const ToreyMainTrailer = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.toreyMainTrailer}
    >
      <source src={videoPaths.toreyMainTrailer} type='video/mp4' />
    </video>
  )
}

export const ToreySkatedDeckVideo = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.toreySkatedDeckVideo}
    >
      <source src={videoPaths.toreySkatedDeckVideo} type='video/mp4' />
    </video>
  )
}

export const ToreyLegendaryRefractorVideo = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.toreyLegendaryRefractorVideo}
    >
      <source src={videoPaths.toreyLegendaryRefractorVideo} type='video/mp4' />
    </video>
  )
}