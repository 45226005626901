import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import {
  useSession,
  SessionProvider,
  SessionProviderProps,
  getSession,
} from 'next-auth/react'
import * as fcl from '@onflow/fcl'
import { useRouter } from 'next/router'
import {
  AuthContext,
  defaultCurrentUser,
  IAuthContext,
  IAuthContextUser,
  useAuth,
} from './AuthContext'
import { loginCallback, logoutCallback, LogoutRedirect } from './authCallbacks'
export * from './AuthContext'
export * from './authCallbacks'
export const useUser = useAuth
// This is the main Provider that handles logic, it is simply called "inner" since we wrap the exported AuthProvider with the SessionProvider so we can useSession in here.
export const AuthProviderInner: FC<PropsWithChildren<IAuthProviderProps>> = ({
  children,
  ...props
}) => {
  const fclAuth = useFclAuth()
  const { session, setSession } = useNextAuthSession()
  const _session = useSession()
  const router = useRouter()

  const logout = useCallback(
    () => logoutCallback(router, props),
    [router, props],
  )

  const login = useCallback(
    () =>
      loginCallback(logout).then((session) => {
        // NOTE: this is needed to get the logged in state to toggle feature flags. WTF ????
        setSession({
          data: session
            ? {
                user: session,
                expires: new Date(session.expiresAt!).toISOString(),
              }
            : null,
          status: session ? 'authenticated' : 'unauthenticated',
        })
        return session
      }),
    [logout],
  )

  const value: IAuthContext = {
    currentUser: fclAuth.currentUser,
    session,
    login,
    logout,
    signIn: login,
    signOut: logout,
  }

  if (typeof window !== 'undefined') {
    // @ts-ignore
    window.__debug.user = session
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export interface IAuthProviderProps {
  redirectOnLogoutRoutes?: LogoutRedirect[]
}

export const AuthProvider: FC<
  PropsWithChildren<
    IAuthProviderProps & { sessionProps?: SessionProviderProps }
  >
> = ({ sessionProps, ...props }) => {
  return (
    <SessionProvider>
      <AuthProviderInner {...props} />
    </SessionProvider>
  )
}

function useFclAuth() {
  const [currentUser, setCurrentUser] =
    useState<IAuthContextUser>(defaultCurrentUser)
  useEffect(() => {
    const unsub = fcl.currentUser.subscribe((fclUser) =>
      setCurrentUser((user: IAuthContextUser) => ({
        ...user,
        ...fclUser,
      })),
    )
    return () => unsub()
  }, [])

  return {
    currentUser,
    setCurrentUser,
  }
}

function useNextAuthSession() {
  const _session = useSession()
  const { currentUser } = useFclAuth()
  const [session, setSession] =
    useState<ReturnType<typeof useSession>>(_session)

  useEffect(() => {
    if (currentUser.loggedIn) {
      getSession().then((__session) => {
        // @ts-ignore
        if (__session) {
          setSession({ data: __session, status: 'authenticated' })
        }
      })
    } else {
      setSession({ data: null, status: 'unauthenticated' })
    }
  }, [currentUser.loggedIn, _session.status])

  return { session, setSession }
}
