import { GrowthBookProvider, useFeature } from '@growthbook/growthbook-react'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useAuth } from '~/auth/client'
import { FCC } from '~/interfaces'
import { growthbook, setDeviceId, updateFeatures } from './growthbook'

export const GrowthBook: FCC = ({ children }) => {
  const router = useRouter()
  const user = useAuth()
  useEffect(() => {
    setDeviceId()
    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      id: user.session.data?.user.id,
      loggedIn: user.currentUser.loggedIn,
      address: user.currentUser.addr,
      browser: navigator.userAgent,
      url: router.pathname,
    })
    updateFeatures()
  }, [router.pathname, user.currentUser.loggedIn, user.session.status])
  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  )
}

export const IfFeatureDisabled: FCC<{
  feature: string
  disabledWhenOff?: boolean
}> = ({ feature, children, disabledWhenOff = false }) => {
  const { off, on, value } = useFeature(feature)

  if (off) return <>{children}</>
  return null
}
