import ReactMailchimp from 'react-mailchimp-subscribe'
import s from './mailchimp.module.scss'
import { withClassNames } from 'styles'
const cx = withClassNames(s)

const DEFAULT_URL =
  'https://abdcollectibles.us17.list-manage.com/subscribe/post?u=4bd8e0a86ab048d47a61348da&id=798d01f996'

export const MailChimp = ({ url = DEFAULT_URL, className = '' }) => {
  return (
    <div className={cx('mailchimp', className)}>
      <ReactMailchimp url={url} />
    </div>
  )
}
