import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import { Session } from 'next-auth'
import {
  clearUserForSentry,
  identifyUserForSentry,
  setSessionUrl,
} from '../sentry/sentry'

const IS_PRODUCTION = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'

export const initLogRocket = () => {
  if (!IS_PRODUCTION) return

  if (typeof window !== 'undefined') {
    LogRocket.init('rsrnm1/abd')

    setupLogRocketReact(LogRocket)
    LogRocket.getSessionURL(setSessionUrl)
  }
}

export const identifyUserForLogRocket = (user: Session['user']) => {
  if (!IS_PRODUCTION) return

  if (typeof window !== 'undefined') {
    if (user?.loggedIn) {
      const email = user?.services?.[0]?.scoped?.email
      LogRocket.identify(email, {
        id: user.id,
        address: user.addr!,
        cid: user.cid!,
        email,
      })
      identifyUserForSentry(user)
    } else {
      clearUserForSentry()
    }
  }
}
