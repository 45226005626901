import styles from './legal-modal-links.module.scss'
import { withClassNames } from 'styles'
const cx = withClassNames(styles)

export const LicenseAgreement = ({ hide }: { hide: () => void }) => (
  <div className={cx('modal-text-container')}>
    <h1 className={cx('text-center py-5')}>
      <b>LICENSE AGREEMENT</b>
    </h1>
    <p>Last updated: March 6, 2022 </p>
    <p className={cx('py-5')}>
      This End User License Agreement (“License”) is made between you (“You” or
      “User”) and ABD Collectibles LLC, a California limited liability company
      (“ABD”, “we”, “us” or “our”).{' '}
    </p>
    <p>
      THIS LICENSE INCLUDES AN ARBITRATION PROVISION. YOU SHOULD REVIEW THE
      ARBITRATION PROVISION CAREFULLY. BY PURCHASING OR OBTAINING ANY RIGHTS
      UNDER THIS LICENSE, OR BY CLICKING TO INDICATE ACCEPTANCE, YOU CONFIRM
      THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY THE ARBITRATION PROVISION.
    </p>
    <h4 className={cx('py-5')}>1. Overview</h4>
    <p>
      The owners of certain copyrighted content (“Content Owners”) have licensed
      ABD to create and market Non-Fungible Tokens (“NFTs”) related to that
      content and to provide specific limited rights in the content to the
      purchasers of the NFTs. By purchasing an NFT from ABD or from another user
      in ABD’s marketplace, you obtain rights (as detailed in this License) in
      one or more works of authorship in video, photography, or other media, as
      identified at the time ABD releases the NFT and linked to that NFT. Each
      work of authorship is a “Media Segment.” The Media Segment (or set of
      Media Segments if there are more than one) associated with an NFT is
      referred to as a “Collectible.”
    </p>
    <p className={cx('py-5')}>
      This License defines the terms and conditions of your right to access,
      display and/or perform (as applicable) the Media Segments associated with
      your ownership of an NFT. By purchasing an NFT associated with a
      Collectible, you agree to all the terms and conditions of this License
      Agreement, as well as the Terms of Service that apply to your use of the
      ABD website at ABDCollectibles.com (“Site”).
    </p>
    <p>
      We may modify this License at any time. We will post any amendments at
      ABDcollectibles.com, indicating the date the License was last updated.
      Your continued use of the Site and/or any rights under this License after
      the “Last Updated” date on the License Agreement will constitute your
      acceptance of and agreement to the changes. If you are not willing and
      able to comply with this Agreement as amended, you may not continue to use
      any rights under this License.
    </p>
    <p className={cx('py-5')}>
      You may not buy or sell NFTs or use or benefit from any rights under this
      License if you are not at least 18 years old (or have not attained any
      greater applicable age of majority where you reside), do not agree to be
      bound by the terms of this Agreement, or your ownership of NFTs or use of
      rights under this License would violate any laws or regulations that apply
      to you.
    </p>
    <h4>2. Ownership, License Grant, and Intellectual Property Rights.</h4>
    <p className={cx('py-5')}>
      a. NFT Ownership. When you complete a legitimate purchase of an NFT
      through the ABD marketplace, ownership of the NFT is transferred to you
      and the transfer is recorded on a blockchain. Upon recordation of a
      transfer via the marketplace, you become the owner of that NFT. Each NFT
      is associated with a specific Collectible but is separate from the
      Collectible and the Media Segment(s) that make up the Collectible. By
      owning the NFT according to the master blockchain record and complying
      with the terms of this License, you obtain licensed rights in (but not
      ownership of) the Media Segment(s) included in the associated Collectible.
    </p>
    <p>
      b. License Grant. Provided that you comply with all terms and conditions
      of this License and for as long as you are the owner of the NFT associated
      with a Collectible as confirmed by the blockchain records of ownership of
      that NFT established by ABD, ABD hereby grants to you (under license
      rights received by ABD from the Content Owner(s) of the Collectible) a
      worldwide, paid-up, non-exclusive, non-sublicensable and non-transferable
      sublicense to access, perform, or display the Media Segments associated
      with the Collectible, but only for your personal, non-commercial use. This
      sublicense applies only within the ABDCollectibles.com marketplace that
      supports the display, purchase and sale of your Collectible and restricts
      access, performance, and display of the Collectible to the verified owner
      of the related NFT, and/or within other environments authorized by ABD and
      the Content Owners that are explicitly added to the foregoing license
      grant by a future amendment to this License.
    </p>
    <p className={cx('py-5')}>
      c. Termination of License Grant. The license granted in Section 2.b. for
      each Collectible continues while you comply with all requirements of this
      License and continue to own the NFT associated with that Collectible. If
      you fail to comply with any term of this License, or sell, trade, donate,
      give away, delete, and/or dispose of your Collectible, the license granted
      to you in Section 2.b. will terminate immediately, and you will have no
      further rights in the Collectible.
    </p>
    <p>
      d. Media Segment Ownership. All right, title and interest in and to the
      Media Segments are retained by the Content Owners, except for the specific
      rights granted by license to ABD, and to you through the sublicense of
      Section 2.b.
    </p>
    <p className={cx('py-5')}>
      e. Media Segment Substitution. If a dispute or potential dispute regarding
      intellectual property or personal rights arises and we reasonably believe
      that a Media Segment must be modified or replaced to avoid infringing the
      rights of another party, we will make good faith efforts to substitute for
      the affected Media Segment in your Collectible a replacement Media Segment
      that is modified in a way that does not materially diminish the central
      event or relevance of the Media Segment, or if that is not commercially
      practical, substitute a different Media Segment providing a similar level
      of interest and perceived value. Your Collectible will thereafter include
      the substituted Media Segment in place of the original Media Segment, and
      all terms and conditions herein will apply to the substituted Media
      Segment.
    </p>
    <h4>3. Restrictions and Enforcement.</h4>
    <p className={cx('py-5')}>
      You may not do or attempt to do (either yourself or by permitting,
      enabling, or encouraging a third party to do) any of the following without
      ABD’s advance written approval:
    </p>
    <p>
      a. Access another user’s account, use another person’s identifying
      information or email address, or otherwise impersonate another person.
    </p>
    <p className={cx('py-5')}>
      b. Create a user account using false information or automated means, or
      use a computer program (“bot”) to simulate or impersonate actions of a
      human user.
    </p>
    <p>
      c. Obtain ownership or control of, or access to, or transfer any NFT or
      associated Collectible without legitimately purchasing and making full and
      final unreversed payment for the NFT using the ABD marketplace.{' '}
    </p>
    <p className={cx('py-5')}>
      d. Commit any act of fraud or deception in obtaining, using, and/or
      transferring or reselling NFTs.
    </p>
    <p>
      e. Propose, advertise, sell, or distribute fractionalized interests in any
      NFT or in the license rights granted to you in any Media Segment
      associated with the NFT.
    </p>
    <p className={cx('py-5')}>
      f. Propose, advertise, sell, or distribute any interest in a Media Segment
      separate from the Collectible and NFT with which it is associated.{' '}
    </p>
    <p>
      g. Modify a Media Segment in any way, including without limitation
      creating any derivative works based on the Media Segment.
    </p>
    <p className={cx('py-5')}>
      h. Promote, advertise, sell, or distribute any product or service (or
      otherwise receive commercial benefit for yourself or any other entity)
      using part or all of any Media Segment, or the name, visual depiction,
      character, or persona of any person depicted in the Media Segment
      (collectively “Personal Rights”).
    </p>
    <p>
      i. Use part or all of a Media Segment and/or any Personal Rights in a
      video or any other media form, except for your own non-commercial use and
      to the limited extent that such use is expressly permitted in this
      License.
    </p>
    <p className={cx('py-5')}>
      j. Use part or all of any Media Segment or any Personal Rights to
      commercially benefit you or another party in any way, and/or using a Media
      Segment or Personal Rights in a way not expressly authorized by this
      License.
    </p>
    <p>
      k. Use part or all of a Media Segment and/or any Personal Rights in
      connection with any public or private statement, message, and/or conduct
      that associates the Media Segment or Personal Rights with (i) a hate group
      or other controversial and/or morally repugnant organization, or (ii)
      advocacy or approval of hate, violence, racism, or other forms of
      discrimination that are unlawful or improper in the United States of
      America.
    </p>
    <p className={cx('py-5')}>
      If you do anything that is prohibited in this Section 3, ABD may in its
      sole discretion and without waiving any of our other rights or remedies,
      immediately suspend or terminate without any notice (i) your user account
      (if you are a user of the Site), (ii) all of your rights under this
      License, and/or (iii) your access to the Collectibles and component Media
      Segments (including any associated information) on the Site, the ABD
      marketplace, and otherwise.
    </p>
    <p>
      We may also, in our sole discretion, void any transaction or activity that
      we deem to have occurred in violation of the terms of this License or
      applicable law, and confiscate any NFTs and/or associated Collectibles
      obtained because of such transaction.
    </p>
    <p className={cx('py-5')}>
      A suspension or termination of your account, this License, or your access
      to the Site and ABD marketplace will not affect your ownership of any NFTs
      that you legitimately purchased prior to such suspension or termination.
      However, such NFTs will no longer be linked with any rights in an
      associated Collectible or be resalable as such; you will not be permitted
      to sell or transfer the NFT, and you will not receive any refund or other
      compensation.
    </p>
    <h4>4.Sale and Transfer.</h4>
    <p className={cx('py-5')}>
      You may sell or otherwise transfer your NFTs only on the ABD marketplace
      where you originally purchased them. All sales and transfers are subject
      to, and must comply with, the Terms of Service of the Site and any
      policies and terms posted for the ABD marketplace.
    </p>
    <p>
      The ABD marketplace records on a blockchain any transfer of an NFT covered
      by this License. ABD is not required to recognize any claim of ownership
      of an NFT and/or claim to associated rights under this License that was
      not processed through the ABD marketplace and properly recorded on the
      blockchain associated with the ABD marketplace. However, ABD may, in its
      sole discretion, adjust ownership records consistent with the terms and
      conditions in this License.
    </p>
    <p className={cx('py-5')}>
      ABD, the Content Owners, any other operator of the marketplace, or their
      assignees may receive fees upon your sale of an NFT via a smart contract
      embedded in the Collectible, or otherwise, as posted on the Site at the
      time of the sale.
    </p>
    <h4>5. Limitations of Liability and Disclaimer.</h4>
    <p className={cx('py-5')}>
      The NFT and associated Collectible license rights are provided only for
      personal entertainment and consumption. The NFT is not provided for
      investment purposes, and is not a “security,” as defined under the
      Securities Act of 1933, Securities Exchange Act of 1934, Investment
      Company Act of 1940, or laws of any U.S. state, as any of the foregoing
      laws have been amended.
    </p>
    <p>
      The NFTs, Collectibles, and Media Segments are provided “as is” without
      warranty of any kind, express or implied, including, but not limited to,
      implied warranties of title, non-infringement, merchantability and fitness
      for a particular purpose, or any other warranty with respect to their
      quality, accuracy, or freedom from error.{' '}
    </p>
    <p className={cx('py-5')}>
      ABD or the Content Owners shall not be liable for any indirect,
      incidental, consequential or other non-direct damages of any kind in
      connection with an NFT, Collectible, Media Segment, or this License, even
      if they have been notified of the possibility of such damages.
    </p>
    <p>
      Under no circumstances shall the total liability of ABD and the Content
      Owners for all damages (in contract, tort, or otherwise) relating to an
      NFT or associated Collectible that arise from (i) your purchase, use
      and/or sale of an NFT, (ii) the terms or operation of this License, and/or
      (iii) our actions pursuant to the License, exceed the amount you paid for
      the NFT.
    </p>
    <h4 className={cx('py-5')}>6. Indemnification.</h4>
    <p>
      You will defend, indemnify, and hold harmless the Providers and their
      affiliates, (and if applicable, teams and players, and its and their
      respective directors, officers, employees, licensors, content providers,
      agents and representatives), from any third-party claim or action arising
      from your breach or alleged breach of this Media License or any
      unauthorized use of the Collectible or Media.
    </p>
    <h4 className={cx('py-5')}>
      7. Dispute Resolution by Binding Arbitration.
    </h4>
    <p>
      Any claim or dispute arising between you and ABD that relates in any way
      to this License or related actions by ABD or its agents, will be resolved
      exclusively through final and binding arbitration, rather than in court.
    </p>
    <p className={cx('py-5')}>
      YOU ARE GIVING UP RIGHTS TO LITIGATE CLAIMS IN A COURT RELATING TO THE
      TERMS OF THIS LICENSE AND RIGHTS GRANTED IN NFTS AND MEDIA SEGMENTS,
      EXCEPT FOR THE EXCLUSIONS HEREIN. RIGHTS THAT YOU WOULD HAVE IN COURT MAY
      NOT BE AVAILABLE.{' '}
    </p>
    <p>
      YOU AND ABD MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS
      AND NOT IN A CLASS ACTION OR OTHER REPRESENTATIVE STATUS. THE ARBITRATOR
      MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON&apos;S OR PARTY&apos;S
      CLAIMS OR PRESIDE OVER ANY CONSOLIDATED, REPRESENTATIVE, OR CLASS
      PROCEEDING. THE ARBITRATOR MAY AWARD RELIEF ONLY TO THE INDIVIDUAL PARTY
      SEEKING RELIEF FOR THAT PARTY&apos;S INDIVIDUAL CLAIMS.{' '}
    </p>
    <p className={cx('py-5')}>
      As an alternative to arbitration, you may file a claim in small claims
      court, if your claims qualify and so long as the matter remains in such
      court and advances only on an individual (non-class, non-representative)
      basis.
    </p>
    <p>
      The arbitration will be conducted by the American Arbitration Association
      (&quot;AAA&quot;) under its Consumer Arbitration Rules as modified by the
      terms herein. The AAA&apos;s rules are available at www.adr.org. A party
      who intends to seek arbitration must first send to the other, by certified
      mail, a Notice of Dispute (&quot;Notice&quot;) describing the nature and
      basis of the claims the party is asserting and the relief sought. The
      Notice to ABD should be sent to ABD Collectibles LLC, Attn: Notice of
      Dispute, 65 West Easy Street #102, Simi Valley, CA 93065.
    </p>
    <p className={cx('py-5')}>
      If you and ABD are unable to resolve the claims in the Notice within 30
      days after the Notice is sent, either party may initiate arbitration
      proceedings. A form for initiating arbitration proceedings is available at
      www.adr.org. In addition to filing this form with the AAA in accordance
      with its rules and procedures, the party initiating the arbitration must
      mail a copy of the completed form to the opposing party. You may send a
      copy to ABD at the following address: ABD Collectibles LLC, Attn:
      Arbitration Notice, 65 West Easy Street #102, Simi Valley, CA 93065.
      Payment of all filing, administration and arbitrator fees will be governed
      by the AAA&apos;s rules. Settlement offers shall not be disclosed to the
      arbitrator
    </p>
    <p>
      The arbitration hearing shall be held in Los Angeles, California. If the
      value of the relief sought is $1,000 or less, either you or ABD may elect
      to have the arbitration conducted by teleconference or based on written
      submissions.
    </p>
    <p className={cx('py-5')}>
      The arbitrator will decide all claims consistent with applicable law and
      will honor privileges recognized by law. The arbitrator shall not be bound
      by rulings in prior arbitrations involving other users. The
      arbitrator&apos;s award shall be final and binding; judgment on the award
      may be entered in any court having jurisdiction thereof.{' '}
    </p>
    <p>
      YOU CAN REJECT THE ARBITRATION REQUIREMENT HEREIN BY MAILING ABD A WRITTEN
      NOTICE POSTMARKED NO LATER THAN 30 DAYS AFTER THE DATE YOU ACQUIRE RIGHTS
      UNDER THIS LICENSE FOR THE FIRST TIME. Any amendment to this License will
      not restart the period for rejecting the arbitration requirement.
    </p>
    <p className={cx('py-5')}>
      The Notice must include your name, street address, city, state, and zip
      code, and must be signed by you. You must mail the Opt-Out notice to ABD
      Collectibles LLC, Attn: Legal Notice, 65 West Easy Street #102, Simi
      Valley, CA 93065. This is the only way to opt out of arbitration; the rest
      of this License will continue to apply. Opting out of arbitration as
      provided in this version of the License has no effect on any other or
      future arbitration agreements you may have with ABD.
    </p>
    <h4>8. General. </h4>
    <p className={cx('py-5')}>
      a. The Content Owners from which rights in Media Segments are obtained
      under this Agreement are intended beneficiaries of this Agreement and may
      enforce against you their rights in their content, and under this License
      and the Terms of Service. There are no other intended third-party
      beneficiaries of this Agreement.
    </p>
    <p>
      b. If any provision of this Agreement is held to be invalid or
      unenforceable, such provision shall be struck out; the remaining
      provisions will remain in force and subject to binding arbitration as
      provided in Section 7. Should any claims be required to proceed on a class
      action basis in a civil court and not in arbitration, litigation of those
      claims shall be stayed pending the outcome of any individual claims in
      arbitration.
    </p>
    <p className={cx('py-5')}>
      c. This License shall not be interpreted to create any partnership, joint
      venture or agency between you and ABD or a Content Owner.
    </p>
    <p>
      d. We may assign our rights under this Agreement in our sole discretion;
      you may not assign or transfer any of your rights under this Agreement
      other than as expressly authorized herein, and any other attempted
      assignment or transfer of rights by you shall be void and have no effect.
    </p>
    <p className={cx('py-5')}>
      e. This License, the Privacy Policy, the Terms of Service, and all
      policies posted through the Site constitute the entire agreement between
      you and ABD and supersede any prior agreements between you and ABD
      (including, but not limited to, any prior versions of this License).
    </p>
    <p>
      f. Failure by ABD to exercise or enforce any of its rights shall not
      constitute a waiver of those rights.
    </p>
    <p className={cx('py-5')}>
      g. The laws of the State of California, without regard to their conflict
      of laws principles, will govern this Agreement and any claim or dispute
      between you and ABD. If the arbitration provisions in Section 7 or in
      other agreements between you and ABD are found not to apply to your claim,
      the state and federal courts located in Los Angeles, California will have
      exclusive jurisdiction, and you agree to submit to the personal
      jurisdiction of these courts and to venue therein.
    </p>
    <p>
      h. Headings are for reference purposes only and do not limit the scope or
      extent of any section.{' '}
    </p>
    <p className={cx('py-5')}>
      i. The provisions of this License which by their nature are intended to
      survive termination of this Agreement will survive its termination.
    </p>
    <button
      onClick={hide}
      className={cx('gradient-button-pack gradient-button-4 p-10')}
    >
      Close
    </button>
  </div>
)
