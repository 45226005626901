import 'styles/globals.scss'
import type { AppProps } from 'next/app'
import { Nav, Footer } from 'components'
import { TrpcAppWrapper } from '~/api/client'
import { ReactQueryDevtools } from 'react-query/devtools'
import { AuthProvider } from '~/auth/client'
import NextNProgress from 'nextjs-progressbar'
import { AnimatePresence } from 'framer-motion'
import { useRouter } from 'next/router'
import { usePageTransitionFix } from '~/client/utils/pageTransitionFix'
import { initLogRocket } from '~/analytics'
import { Analytics } from '@vercel/analytics/react'
import { GrowthBook } from '~/analytics/growthbook/react'

initLogRocket()
function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  usePageTransitionFix()
  return (
    <AuthProvider
      redirectOnLogoutRoutes={[
        '/my-collection',
        '/unopenedPacks',
        '/payment',
        '/payment/[packType]',
        '/payment/success',
      ]}
    >
      <GrowthBook>
        <NextNProgress />
        <Nav />
        <AnimatePresence exitBeforeEnter>
          <Component key={router.pathname} {...pageProps} />
        </AnimatePresence>
        <Footer />
        <ReactQueryDevtools />
      </GrowthBook>
      <Analytics />
    </AuthProvider>
  )
}

export default TrpcAppWrapper(MyApp)

export { reportWebVitals } from 'next-axiom'
