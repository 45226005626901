import { createContext, useContext } from 'react'
import { FCC, IPack, IPackType, IPackTypeLower } from '~/interfaces'
import {
  PackTypeDetailsProvider,
  usePackTypeDeailsSinglePack,
} from './PackTypeDetailsContext'

export const SelectedPackTypeProvider: FCC<{ value: IPack['type'] }> = ({
  children,
  value,
}) => {
  return (
    <PackTypeDetailsProvider>
      <SelectedPackTypeContext.Provider value={capitalizePackType(value)}>
        {children}
      </SelectedPackTypeContext.Provider>
    </PackTypeDetailsProvider>
  )
}

export const SelectedPackTypeContext = createContext<IPack['type']>('Standard')
export const useSelectedPackTypeCtx = () => useContext(SelectedPackTypeContext)
export const useSelectedPackTypeCtxLowerCase = () =>
  lowercasePackType(useSelectedPackTypeCtx())
export const useSelectedPackTypeDetails = () => {
  const packType = useSelectedPackTypeCtx()
  return usePackTypeDeailsSinglePack(packType)!
}

export const isValidPackTypeName = (name: string) =>
  ['premium', 'standard'].includes(name.toLowerCase())

export const capitalizePackType = (
  name: IPackType | IPackTypeLower,
): IPackType => capitalizeFirstLetter(name)
export const lowercasePackType = (
  name: IPackType | IPackTypeLower,
): IPackTypeLower => name.toLowerCase() as IPackTypeLower

function capitalizeFirstLetter<Str extends string>(str: Str): Capitalize<Str> {
  return (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<Str>
}
